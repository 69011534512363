import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Client } from 'src/app/core/entity/client';
import { Task } from 'src/app/core/entity/task';
import { ClientService } from 'src/app/core/services/clients.service';
import { Permission } from 'src/app/core/entity/permission';
import { UserAuthFacade } from 'src/app/core/facades/user-auth.facade';
import { AppState } from '@app/core/state/appState';
import { Store } from '@ngrx/store';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { FirmCollaboratorActions } from '@app/core/state/firm-collaborators/firm-collaborators.actions';

const enum ApplicationType {
  BL = 'BL',
  BLSkatt = 'BLSkatt',
  BLBokslut = 'BLBokslut',
}

export interface ApplicationLink {
  type: ApplicationType;
  title: string;
  task: Task;
  disabled: boolean;
}

@Component({
  selector: 'app-client-cloud-service',
  templateUrl: './client-cloud-service.component.html',
})
export class ClientCloudServiceComponent {
  @Input()
  get client() {
    return this.internalClient;
  }

  set client(client: Client) {
    this.internalClient = client;
    this.hasCloudApiKey$.next(!!client.cloudApiKey);
  }

  @Input()
  get active() {
    return this.internalActive;
  }

  set active(value: boolean) {
    this.internalActive = value;
    if (value === true) {
      this.initVariables();
    }
  }

  @Output() updated: EventEmitter<Client> = new EventEmitter<Client>();
  @Output() updatedWithoutSave: EventEmitter<Client> = new EventEmitter<Client>();

  editCloudApiKey = false;
  updatingCloudApiKey = false;
  invalidCloudApiKeyError = false;
  displayNewHash = false;
  displayAdvisoryDeletionInfoDialog = false;
  displayDeletionCloudApiKeyWarning = false;
  displayConnectToCloudCompany = false;
  displayMismatchCloudApiKeyWarning = false;
  cloudVerifyResponse: { name: string; orgNumber: string; cloudApiKey: string };
  editCloudApiKeyValue = '';
  hasAccessToChange$: Observable<{ value: boolean }>;
  hasCloudApiKey$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  missingAccessTooltipText$: Observable<string>;
  showMoreInformationAboutCloudApiKey = false;

  private internalClient: Client;
  private internalActive: boolean = false;
  private mismatchCloudApiKeyResponseSubject = new Subject();

  get isNewClient() {
    return !this.client.id || this.client.id === -1;
  }

  get isPossibleToConnectToCloudCompany() {
    return !this.client.archived && !this.isNewClient && !this.client.cloudApiKey && this.client.corporateIdentity;
  }

  constructor(
    private clientService: ClientService,
    private userAuthFacade: UserAuthFacade,
    private store: Store<AppState>,
  ) {
    const combined$ = combineLatest([this.userAuthFacade.isAdminOrResponsibleForClient$, this.hasCloudApiKey$]);
    this.missingAccessTooltipText$ = combined$.pipe(map((values) => this.getMissingAccessTooltipText(values)));
    this.hasAccessToChange$ = combined$.pipe(
      map(([isAdminOrRespUser, hasCloudApiKey]: [boolean, boolean]) => ({
        value: isAdminOrRespUser && hasCloudApiKey,
      })),
    );
  }

  private initVariables() {
    this.editCloudApiKey = false;
    this.updatingCloudApiKey = false;
    this.invalidCloudApiKeyError = false;
    this.displayNewHash = false;
    this.displayAdvisoryDeletionInfoDialog = false;
    this.displayDeletionCloudApiKeyWarning = false;
    this.displayConnectToCloudCompany = false;
    this.displayMismatchCloudApiKeyWarning = false;
    this.editCloudApiKeyValue = '';
    this.mismatchCloudApiKeyResponseSubject = new Subject();
  }

  showEditCloudApiKeyField() {
    this.editCloudApiKey = true;
    this.editCloudApiKeyValue = this.client.cloudApiKey;
  }

  deleteCloudKey() {
    if (this.client.advisoryTool) {
      this.displayAdvisoryDeletionInfoDialog = true;
      return;
    }
    this.displayDeletionCloudApiKeyWarning = true;
  }

  updateCloudApiKey() {
    this.invalidCloudApiKeyError = false; // hides the error
    this.updatingCloudApiKey = true; // disables the save button

    this.clientService
      .verifyCloudApiKey(this.editCloudApiKeyValue)
      .pipe(
        tap((response) => {
          if (!response) {
            throw new Error();
          }
        }),
        mergeMap((response) => {
          // validates the org number if it's the same as on the client card
          if (response.orgNumber !== this.client.corporateIdentity) {
            return this.showMismatchCloudApiKeyWarning(response);
          }
          return of(true);
        }),
        map(() => ({ ...this.client, cloudApiKey: this.editCloudApiKeyValue.trim() })),
      )
      .subscribe({
        next: (client) => {
          this.editCloudApiKey = false;
          this.updatingCloudApiKey = false;
          this.updated.emit(client);
        },
        error: () => {
          this.invalidCloudApiKeyError = true;
          this.updatingCloudApiKey = false;
        },
        complete: () => {
          this.updatingCloudApiKey = false;
        },
      });
  }

  connectCloudCompany() {
    this.editCloudApiKey = false;
    this.invalidCloudApiKeyError = false;
    this.displayConnectToCloudCompany = true;
  }

  onClientConnectedToCloudCompany(client: Client) {
    this.updated.emit(client);
  }

  copyText() {
    const el = document.createElement('textarea');
    el.value = this.client.alertIdentity;
    document.body.appendChild(el);
    el.focus();
    el.select();
    el.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(el.value);
    document.body.removeChild(el);

    this.store.dispatch(
      ToastActions.showInfoMessage({
        summary: 'Nyckel kopierad',
        detail: `Byråstödsnyckel ${el.value} har kopierats `,
      }),
    );
  }

  createNewHash(createNew: boolean) {
    if (createNew) {
      this.clientService.recreateHash(this.client.id).subscribe((client) => {
        this.client.alertIdentity = client.alertIdentity;
        this.updatedWithoutSave.emit(client);
      });
    }
    this.displayNewHash = false;
  }

  showMismatchCloudApiKeyWarning(cloudResponse: { name: string; orgNumber: string; cloudApiKey: string }) {
    this.displayMismatchCloudApiKeyWarning = true;
    this.cloudVerifyResponse = cloudResponse;

    return this.mismatchCloudApiKeyResponseSubject.asObservable().pipe(
      take(1),
      tap(() => {
        this.displayMismatchCloudApiKeyWarning = false;
      }),
      switchMap((response) => (response === true ? of(true) : EMPTY)),
    );
  }

  dismissMismatchWarning() {
    this.mismatchCloudApiKeyResponseSubject.next(false);
  }

  confirmMismatchWarning() {
    this.mismatchCloudApiKeyResponseSubject.next(true);
  }

  dismissDeletionCloudApiKeyWarning() {
    this.displayDeletionCloudApiKeyWarning = false;
  }

  confirmDeletionCloudApiKeyWarning() {
    const copyOfClient = { ...this.client, cloudApiKey: '' };

    this.displayDeletionCloudApiKeyWarning = false;
    this.editCloudApiKey = false;
    this.updatingCloudApiKey = false;

    this.store.dispatch(FirmCollaboratorActions.clearFirmCollaboratorsForClient({ clientId: this.client.id }));
    this.updated.emit(copyOfClient);
  }

  permissionUpdated(_permissions: Permission[]) {}

  private getMissingAccessTooltipText([isAdminOrRespUser, hasCloudApiKey]: [boolean, boolean]) {
    if (!hasCloudApiKey) {
      return 'Molndatabasnyckel saknas för att aviseringsinställningarna ska kunna sättas.';
    }
    if (!isAdminOrRespUser) {
      return 'Bara nuvarande klientansvarig eller byråadministratörer kan ändra aviseringsinställningarna.';
    }
    return '';
  }
}
