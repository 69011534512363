import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogData, FormDialogBaseComponent } from '../form-dialog-base/form-dialog-base.component';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { openDialog } from '../../misc/openDialog';
import { FirmSettingsDialog } from 'src/app/firm-settings/firm.settings.dialog';

@Component({
  selector: 'app-due-compliance-teaser-dialog',
  template: `
    <div>
      <p>
        Nu finns möjlighet att koppla Byråstödet direkt till Due Compliance för att få bästa stöd för kundkännedom (KYC)
        och för efterlevnad av penningtvättslagen (PTL). Statusen på din PTL-kontroll kommer visas i byråstödet
        automatiskt. Läs mer i nyhetsbrevet som du hittar på startsidan.
      </p>
      <div class="px-8 py-2">
        <ul class="list-disc">
          <li>
            Det här låter intressant, eller hur?
            <a href="https://www.bjornlunden.se/redovisningsbyr%c3%a5/program/due-compliance-kyc" target="_blank"
              >Läs mer här och boka en demo.</a
            >
          </li>
          <li>
            Är du redan kund hos Due Compliance och har fått ditt API-lösenord kan du gå direkt till Systemuppgifter för
            att
            <a class="cursor-pointer" (click)="openFirmSettings()">aktivera integrationen.</a>
          </li>
          <li>
            Är du kund hos Due Compliance men inte erhållit ditt API-lösenord kontakta Due Compliance via mail på
            <a href="mailto:support@duecompliance.se">support&#64;duecompliance.se</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex mt-4 justify-end">
      <bl-frontend-button text="Stäng" variant="primary" brand="blapp" (click)="close({})"></bl-frontend-button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendButtonComponent],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DueComplianceTeaserDialogComponent extends FormDialogBaseComponent {
  openFirmSettings() {
    openDialog(this.dialogService, FirmSettingsDialog, { tabToOpen: 'Systemuppgifter' });
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: 'Koppla Byråstöd till Due Compliance',
      width: '700px',
    };
  }
}
