<div class="flex w-full items-center mb-14 pb-5 border-b border-bl-grey-200" [ngClass]="{ 'opacity-60': hasFallenOut }">
  <div class="w-1/12">
    <bl-frontend-iconbutton name="chevron-left" size="2xl" (click)="previousDate()" *ngIf="previousButtonVisible" />
  </div>
  <div class="text-center w-10/12">
    <p class="mb-0 text-lg font-bold">Återbäring</p>
  </div>
  <div class="w-1/12 flex justify-end">
    <bl-frontend-iconbutton name="chevron-right" size="2xl" (click)="nextDate()" *ngIf="nextButtonVisible" />
  </div>
</div>
<div class="grid grid-cols-8" *ngIf="displayedData?.length">
  <ng-container *ngFor="let data of displayedData; let idx = index">
    <div class="flex justify-center items-center">
      <!-- grupp -->
      <div class="chart-group w-6 md:w-24" *ngIf="calculateBarHeightForPeriod(data) as barData">
        <bl-frontend-tooltip [heading]="barData.longDate" [content]="barData.tooltip">
          <!-- staplar -->
          <div class="grid grid-flow-col gap-1 items-end h-32">
            <div
              class="bar-value rounded relative"
              [ngStyle]="{ height: barData.refundPercent + '%' }"
              [ngClass]="{
                'current-month': barData.currentMonth,
                'bg-bl-grey-300': hasFallenOut,
                'bg-bl-orange-500': !hasFallenOut,
              }"></div>
          </div>
          <div class="mt-5 text-center chart-label">{{ barData.shortDate }}</div>
        </bl-frontend-tooltip>
      </div>
    </div>
  </ng-container>
</div>
