import { Component } from '@angular/core';
import { BlFrontendButtonComponent } from '@core/wrappers/bl-frontend-button.component';
import { FormDialogBaseComponent } from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { FirmCollaboratorType } from '@app/core/state/types/firm-collaborator.types';
import { CommonModule } from '@angular/common';

interface ConfirmRemoveFirmCollaboratorDialogData {
  firmCollaborator: FirmCollaboratorType;
}

interface ConfirmRemoveFirmCollaboratorDialogResult {
  confirmed?: boolean;
}

@Component({
  template: `
    <p>Är du säker på att du vill ta bort samarbetsbyrån "{{ firmCollaborator.name }}"?</p>
    <div class="flex justify-end mt-6 gap-2">
      <bl-frontend-button text="Avbryt" variant="secondary" (click)="close(null)"></bl-frontend-button>
      <bl-frontend-button
        text="Ja, fortsätt"
        variant="primary"
        (click)="close({ confirmed: true })"></bl-frontend-button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendButtonComponent],
})
export class ConfirmRemoveFirmCollaboratorDialogComponent extends FormDialogBaseComponent<
  ConfirmRemoveFirmCollaboratorDialogData,
  ConfirmRemoveFirmCollaboratorDialogResult
> {
  firmCollaborator: FirmCollaboratorType;

  protected override onInitialized = () => {
    this.firmCollaborator = this.config.data.firmCollaborator;
  };

  protected static override getDialogConfig() {
    return {
      header: 'Ta bort samarbetsbyrå?',
      width: '500px',
    };
  }
}
