import { first, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Client } from '../entity/client';
import { Type } from '../entity/type';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { ClientType, ClientTypeType } from '../state/types';
import { AppState } from '../state/appState';
import { ToastActions } from '../state/toast/toast.actions';
import { toClientTypeTransformer, toClientTypeTypesTransformer } from '../state/transformers/transformers';
import { withFirstTeamIds } from '../misc/rxjs-operators';
import { TeamsSelectors } from '../state/teams/teams.selectors';
import { FirmCollaboratorMetaType, FirmCollaboratorType } from '../state/types/firm-collaborator.types';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'client';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  clientsBS = new BehaviorSubject({});
  clients$: Observable<object>;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
    this.useCache(['TYPES']);
    this.clients$ = this.clientsBS.asObservable();
  }

  getAllFilteredClients(): Observable<Client[]> {
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http
          .get<Client[]>(this.url, this.options.getAuthorizationHeaderWithEmptyBody(ids))
          .pipe(this.catchErrorAndShowMessage()),
      ),
    );
  }

  getAllClients(): Observable<Client[]> {
    return withFirstTeamIds(this.store).pipe(
      switchMap(() =>
        this.store.select(TeamsSelectors.selectTeamSelector).pipe(
          first(),
          switchMap(({ ids }) =>
            this.http
              .get<Client[]>(this.url, this.options.getAuthorizationHeaderWithEmptyBody(ids))
              .pipe(this.catchErrorAndShowMessage()),
          ),
        ),
      ),
    );
  }

  getAllClientTypes(): Observable<ClientType[]> {
    return this.getAllClients().pipe(map((clients) => clients.map(toClientTypeTransformer.transform)));
  }

  getAllFilteredClientTypes(): Observable<ClientType[]> {
    return this.getAllFilteredClients().pipe(map((clients) => clients.map(toClientTypeTransformer.transform)));
  }

  getAllArchivedClients(): Observable<Client[]> {
    return this.http
      .get<Client[]>(`${this.url}/archived`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getClientById(id: number): Observable<Client> {
    return this.http
      .get<Client>(`${this.url}/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getClientsWithAdvisoryTool(): Observable<Client[]> {
    return this.getAllFilteredClients().pipe(
      map((clients) => clients.filter((client) => client.advisoryTool && !!client.cloudApiKey)),
    );
  }

  recreateHash(id: number): Observable<Client> {
    return this.http.post<Client>(`${this.url}/${id}/recreateHash`, null, this.options.getAuthorizationHeader()).pipe(
      tap(() =>
        this.store.dispatch(
          ToastActions.showInfoMessage({ summary: 'Programnyckel', detail: 'Ny programnyckel har skapats' }),
        ),
      ),
      this.catchErrorAndShowMessage(),
    );
  }

  getClientsTypes(): Observable<Type[]> {
    if (!this.hasCacheExpired('TYPES')) {
      return this.getCacheObservable<Type[]>('TYPES');
    }

    return this.http.get<Type[]>(`${this.url}/types`, this.options.getAuthorizationHeaderWithEmptyBody()).pipe(
      tap((result) => this.updateCache(result, 'TYPES')),
      this.catchErrorAndShowMessage(),
    );
  }

  getClientTypes(): Observable<ClientTypeType[]> {
    return this.getClientsTypes().pipe(map((types) => types.map(toClientTypeTypesTransformer.transform)));
  }

  addClient(client: Client, selectedTeams: number[] = []): Observable<Client> {
    return this.http
      .put<Client>(this.url, client, this.options.getAuthorizationHeaderWithoutContentType(selectedTeams))
      .pipe(this.catchErrorAndShowMessage());
  }

  upsertClientType(client: ClientType): Observable<ClientType> {
    return withFirstTeamIds(this.store).pipe(switchMap(({ ids }) => this.upsertClientTypeWithTeams(client, ids)));
  }

  upsertClientTypeWithTeams(client: ClientType, teamIds: number[]): Observable<ClientType> {
    const options = this.options.getAuthorizationHeaderWithoutContentType(teamIds);
    return this.http.put<Client>(this.url, client, options).pipe(this.catchErrorAndShowMessage());
  }

  deleteClient(id: number): Observable<Client> {
    return this.http
      .delete<Client>(`${this.url}/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  verifyCloudApiKey(cloudApiKey: string) {
    return this.http.get<{ name: string; orgNumber: string; cloudApiKey: string }>(
      `${this.url}/verifyKey/${cloudApiKey}`,
      this.options.getAuthorizationHeaderWithEmptyBody(),
    );
  }

  getAgentUrl(clientId: number) {
    return this.http
      .get<any>(`${this.url}/agentCall/${clientId}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getIntegrationKey(clientId: number) {
    return this.http
      .get<any>(`${this.url}/blappIntegrationsKey/${clientId}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  activateSustainabilityReporting(clientId: number): Observable<void> {
    const url = `${this.url}/${clientId}/sustainabilityreporting`;
    return this.http.post<void>(url, null, this.options.getAuthorizationHeaderWithEmptyBody());
  }

  deactivateSustainabilityReporting(clientId: number): Observable<void> {
    const url = `${this.url}/${clientId}/sustainabilityreporting`;
    return this.http.delete<void>(url, this.options.getAuthorizationHeaderWithEmptyBody());
  }

  archiveDeletableClient(id: number): Observable<void> {
    return this.http
      .post<void>(`${this.url}/${id}/archive`, null, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getFirmCollaborators(clientId: number): Observable<FirmCollaboratorMetaType> {
    const collaboratorsUrl = `${environment.serverUrl}firmcollaborators/${clientId}`;

    return this.http
      .get<FirmCollaboratorMetaType>(collaboratorsUrl, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  updateFirmCollaborators(clientId: number, userId: number, firmKeys: string[]): Observable<FirmCollaboratorType[]> {
    const collaboratorsUrl = `${environment.serverUrl}firmcollaborators/${clientId}`;

    return this.http
      .post<FirmCollaboratorType[]>(
        collaboratorsUrl,
        { userId, collaborators: firmKeys },
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }
}
