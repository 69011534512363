import { PrivilegeType } from '../state/types';

export enum Role {
  BL_ADMIN = 'BL_ADMIN', // bl_admin används internt på BL användare i vissa fall
  FIRM_ADMIN = 'FIRM_ADMIN',
  FIRM_USER = 'FIRM_USER',
}

export enum RoleLabel {
  FIRM_ADMIN = 'Byråadministratör',
  FIRM_USER = 'Byråmedarbetare',
}

export const RoleAsArray = Object.keys(RoleLabel).map((rl) => ({
  id: rl,
  label: (RoleLabel as Record<string, RoleLabel>)[rl],
}));

export class User {
  public id: number;
  public name = '';
  public email = '';
  public initials: string;
  public username: string;
  public firm = { name: '', corporateIdentity: '' };
  public role: Role;
  public roleLabel: string;
  public active = false;
  public privilege?: PrivilegeType = PrivilegeType.RW;
  public teams?: number[];
  partnerProgram = false;
  languageCode: string;

  static from(config: any): User {
    return Object.assign(new User(), config, { roleLabel: (RoleLabel as Record<string, RoleLabel>)[config.role] });
  }

  static nothingButId(id: number) {
    const user = new User();
    user.nothingButId(id);
    return user;
  }

  nothingButId(id: number) {
    this.id = id;
    this.name = undefined;
    this.email = undefined;
    this.initials = undefined;
    this.username = undefined;
    this.firm = undefined;
    this.role = undefined;
    this.roleLabel = undefined;
    this.active = false;
    this.privilege = undefined;
    this.languageCode = undefined;
  }
}
