import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { FirmCollaboratorDialogComponent } from './firm-collaborator-dialog.component';
import { FirmCollaboratorActions } from '@app/core/state/firm-collaborators/firm-collaborators.actions';
import { Observable, of } from 'rxjs';
import { AppState } from '@app/core/state/appState';
import { FirmCollaboratorSelectors } from '@app/core/state/firm-collaborators/firm-collaborators.selectors';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { openDialog } from '@app/shared/misc/openDialog';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';

@Component({
  selector: 'app-firm-collaborators',
  template: ` <h1 class="!text-base font-semibold">Samarbetsbyrå</h1>
    <p class="mb-4">
      Önskar du ge en annan BL Byrå tillgång till denna klient i BL Administration. <br />Ange den byråns
      Redovisningsbyrånyckel nedan. Byrån hittar nyckeln i BL Administration under licensuppgifter.
    </p>
    <div class="flex flex-col">
      <div class="flex items-center">
        <span class="font-semibold mr-2">Kopplade byråer:</span>
      </div>
      <div class="flex flex-nowrap">
        <span class="p-2 border border-bl-grey-500 bg-bl-grey-100 min-w-72">{{ firmCollaboratorsText$ | async }}</span>
        <button
          type="button"
          class="btn btn-primary ml-2"
          (click)="editFirmCollaborators()"
          [disabled]="!(canEditCollaborators$ | async)"
          title="Redigera kopplade byråer">
          <i class="far fa-edit"></i>
        </button>
      </div>
    </div>`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class FirmCollaboratorComponent implements OnInit {
  @Input() clientId: number;
  @Input() cloudApiKey: string;

  firmCollaboratorsText$: Observable<string>;
  canEditCollaborators$: Observable<boolean>;

  get hasCloudApiKey(): boolean {
    return Boolean(this.cloudApiKey);
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
    private allInclusiveService: AllInclusiveService,
  ) {}

  ngOnInit(): void {
    this.firmCollaboratorsText$ = this.getFirmCollaboratorsTextObservable();
    this.canEditCollaborators$ = this.getCanEditCollaboratorsObservable();
    this.loadFirmCollaborators();
  }

  editFirmCollaborators() {
    openDialog(this.dialogService, FirmCollaboratorDialogComponent, { clientId: this.clientId });
  }

  private getFirmCollaboratorsTextObservable(): Observable<string> {
    if (!this.allInclusiveService.isLoggedInWithBLTAService) {
      return of('Endast medarbetare med BL Byrå kan göra detta');
    }

    if (!this.hasCloudApiKey) {
      return of('Molndatabasnyckel måste finnas för att kunna koppla byråer');
    }

    return this.store.select(FirmCollaboratorSelectors.selectCollaboratorsText(this.clientId));
  }

  private getCanEditCollaboratorsObservable(): Observable<boolean> {
    if (!this.allInclusiveService.isLoggedInWithBLTAService) {
      return of(false);
    }
    return this.store.select(FirmCollaboratorSelectors.canEditCollaboratorsForClientId(this.clientId));
  }

  private loadFirmCollaborators() {
    if (!this.hasCloudApiKey || !this.allInclusiveService.isLoggedInWithBLTAService) {
      return;
    }
    this.store.dispatch(FirmCollaboratorActions.loadFirmCollaboratorsForClientIfMissing({ clientId: this.clientId }));
  }
}
