import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { PartnerProgramProfileCardComponent } from '../partnerprogram-profile/partnerprogram-profile-card.component';
import { PartnerFirm } from '@app/core/state/types/partner.types';
import { isNullOrUndefined } from '@app/core/entity/validation';

@Component({
  selector: 'app-partnerprogram-signup',
  template: `
    <ng-container *ngIf="!isPartner">
      <div class="w-3/5 mb-6">
        <app-partnerprogram-profile-card>
          <div
            class="bg-contain bg-no-repeat"
            style="
            background-image: url(assets/partner-card.png);
            background-position: top right;
            background-size: 200px;
          ">
            <p class="font-bold">Bli byråpartner!</p>
            <p class="w-1/2 mb-24">Gå med i vårt Partnerprogram och ta del av alla utvecklingsmöjligheter!</p>
            <bl-frontend-button text="Läs mer och ansök" (click)="openInfoPage()"></bl-frontend-button>
          </div>
        </app-partnerprogram-profile-card>
      </div>
    </ng-container>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendButtonComponent, PartnerProgramProfileCardComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramSignUp {
  @Input() firm: PartnerFirm | null;

  get isPartner() {
    // null is not a partner
    // zero is a partner that has fallen out of the lowest level
    return !isNullOrUndefined(this.firm?.level?.value);
  }

  openInfoPage() {
    window.open('https://www.bjornlunden.se/partnerprogram', '_blank');
  }
}
