import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FirmCollaboratorSelectors } from '@app/core/state/firm-collaborators/firm-collaborators.selectors';
import { FirmCollaboratorType } from '@app/core/state/types/firm-collaborator.types';
import {
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { BystTableModule } from '@app/shared/components/table/table.module';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { FirmCollaboratorActions } from '@app/core/state/firm-collaborators/firm-collaborators.actions';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { AddFirmCollaboratorDialogComponent } from './add-firm-collaborator-dialog.component';
import { openDialog } from '@app/shared/misc/openDialog';
import { ConfirmRemoveFirmCollaboratorDialogComponent } from './confirm-remove-firm-collaborator-dialog.component';

interface FirmCollaboratorDialogData {
  clientId: number;
}

@Component({
  template: `<app-table
      [columns]="columns"
      [data]="tableData$ | async"
      [columnSelector]="false"
      [searchField]="false"
      [paginator]="false">
      <ng-template tableTemplate="bodyCell" tableBodyCellField="action" let-rowData="rowData">
        <div class="text-center">
          <span (click)="removeFirmCollaborator(rowData)" class="far fa-trash-alt cursor-pointer"></span>
        </div>
      </ng-template>
    </app-table>
    <div class="flex justify-between mt-4">
      <bl-frontend-button
        text="Lägg till Samarbetsbyrå"
        variant="secondary"
        (click)="showAddFirmCollaboratorsDialog()"></bl-frontend-button>
      <bl-frontend-button text="Stäng" (click)="close(null)"></bl-frontend-button>
    </div>`,
  standalone: true,
  imports: [CommonModule, BystTableModule, BlFrontendButtonComponent],
})
export class FirmCollaboratorDialogComponent extends FormDialogBaseComponent<FirmCollaboratorDialogData, DialogResult> {
  tableData$: Observable<FirmCollaboratorType[]>;
  columns: TableColumn[] = [
    { field: 'name', header: 'Kopplad BL Byrå' },
    { field: 'firmKey', header: 'Redovisningsbyrånyckel' },
    { field: 'action', header: 'Ta bort', width: '80px', sortable: false },
  ];

  private currentClientId: number;

  protected override onInitialized = () => {
    this.currentClientId = this.config.data.clientId;

    this.tableData$ = this.store.select(
      FirmCollaboratorSelectors.selectFirmCollaboratorsByClientId(this.currentClientId),
    );
  };

  protected static override getDialogConfig(): DynamicDialogConfig<FirmCollaboratorDialogData> {
    return {
      header: 'Samarbetsbyråer',
      width: '700px',
    };
  }

  removeFirmCollaborator(firmCollaborator: FirmCollaboratorType) {
    openDialog(this.dialogService, ConfirmRemoveFirmCollaboratorDialogComponent, {
      firmCollaborator,
    }).subscribe((result) => {
      if (result?.confirmed) {
        this.store.dispatch(
          FirmCollaboratorActions.removeFirmCollaborator({
            clientId: this.currentClientId,
            firmKey: firmCollaborator.firmKey,
          }),
        );
      }
    });
  }

  showAddFirmCollaboratorsDialog() {
    openDialog(this.dialogService, AddFirmCollaboratorDialogComponent, { clientId: this.currentClientId });
  }
}
