import { createFeature, createReducer, on } from '@ngrx/store';
import { FirmCollaboratorMetaType, FirmCollaboratorType } from '../types/firm-collaborator.types';
import { FirmCollaboratorActions } from './firm-collaborators.actions';

export interface FirmCollaboratorState {
  firmCollaborators: Record<number, FirmCollaboratorMetaType>;
  error: unknown;
}

export const initialFirmCollaboratorState: FirmCollaboratorState = {
  firmCollaborators: {},
  error: null,
};

const addFirmCollaborators = (
  state: FirmCollaboratorState,
  clientId: number,
  firmCollaboratorMetaData: FirmCollaboratorMetaType,
): FirmCollaboratorState => {
  const firmCollaboratorsByClientId = {
    ...state.firmCollaborators,
    [clientId]: firmCollaboratorMetaData,
  };

  return { ...state, firmCollaborators: firmCollaboratorsByClientId, error: null };
};

const updateFirmCollaboratorsOnClient = (
  state: FirmCollaboratorState,
  clientId: number,
  firmCollaborators: FirmCollaboratorType[],
): FirmCollaboratorState => {
  const clientFirmCollaborators = state.firmCollaborators[clientId] || null;

  if (!firmCollaborators) {
    return state;
  }

  const firmCollaboratorsByClientId = {
    ...state.firmCollaborators,
    [clientId]: { ...clientFirmCollaborators, collaborators: [...firmCollaborators] },
  };
  return { ...state, firmCollaborators: firmCollaboratorsByClientId, error: null };
};

export const firmCollaboratorFeature = createFeature({
  name: 'firmCollaborators',
  reducer: createReducer(
    initialFirmCollaboratorState,

    on(FirmCollaboratorActions.loadFirmCollaboratorsForClientSucceeded, (state, { clientId, firmCollaboratorMeta }) =>
      addFirmCollaborators(state, clientId, firmCollaboratorMeta),
    ),

    on(FirmCollaboratorActions.updateFirmCollaboratorSucceeded, (state, { clientId, firmCollaborators }) =>
      updateFirmCollaboratorsOnClient(state, clientId, firmCollaborators),
    ),

    on(FirmCollaboratorActions.clearFirmCollaboratorsForClient, (state, { clientId }) => {
      const firmCollaborators = { ...state.firmCollaborators };
      delete firmCollaborators[clientId];
      return { ...state, firmCollaborators, error: null };
    }),
  ),
});
