import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { Client } from 'src/app/core/entity/client';
import { CloudCompanyService } from 'src/app/core/services/cloud-company.service';
import { BlaCompany } from 'src/app/core/entity/bla-company';
import { BaseCloudImportExportComponent } from './base-cloud-import-export.component';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { isIdentityMatch } from '@app/core/misc/identity-matching';

type ImportTableRowType = BlaCompany & { statusText: string };

@Component({
  selector: 'app-import-missing-companies-to-byst',
  templateUrl: './import-missing-companies-to-byst.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseCloudImportExportComponent, useExisting: ImportMissingCompaniesToBystComponent }],
})
export class ImportMissingCompaniesToBystComponent extends BaseCloudImportExportComponent {
  @Input()
  set includeHidden(value: boolean) {
    this.filterSubject.next({ includeHidden: value });
  }

  @Output() updating: EventEmitter<void> = new EventEmitter<void>();
  @Output() updated: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected override cloudCompanyService: CloudCompanyService, private store: Store<AppState>) {
    super(cloudCompanyService);

    this.cloudCompanies$ = combineLatest([this.loadedDataSubject, this.filterSubject]).pipe(
      map(([data, filter]) => data.filter((company: BlaCompany) => !company.hidden || filter.includeHidden)),
      map((data) => this.appendStatusText(data)),
    );
  }

  listMessage = this.LIST_MESSAGES.EMPTY_CLOUD_COMPANIES;
  columns = [
    { field: 'orgNumber', header: 'Orgnr' },
    { field: 'name', header: 'Namn' },
    { field: 'systemNote', header: 'Systemnot' },
    { field: 'publicKey', header: 'Molndatabasnyckel' },
    { field: 'statusText', header: 'Info databas' },
  ];
  cloudCompanies$: Observable<ImportTableRowType[]>;
  selectedCloudCompanies: BlaCompany[] = [];

  get hasSelectedCompanies() {
    return this.selectedCloudCompanies.length > 0;
  }

  loadList(): void {
    this.getListData().subscribe({
      next: (response: BlaCompany[]) => {
        this.loadedDataSubject.next(response);
      },
      error: (err: unknown) => console.log('Error loading import list', err),
    }
    );
  }

  execute(): void {
    if (!this.hasSelectedCompanies) {
      return;
    }

    this.updating.emit();

    this.cloudCompanyService.importCloudCompaniesToByst(this.selectedCloudCompanies).subscribe(
      (response) => {
        if (!response.success && response.error) {
          this.store.dispatch(
            ToastActions.showErrorMessage({ summary: 'Ett fel har uppstått', detail: response.error }),
          );
        }
        this.selectedCloudCompanies = [];
        this.appendClients(response.createdClients);
        this.loadList();
        this.updated.emit();
      },
      () => {
        this.updated.emit();
      },
    );
  }

  // Implementation of abstract parent method
  protected filterList(clients: Client[], blaCompanies: BlaCompany[]): BlaCompany[] {
    return this.filterCompanies(blaCompanies).filter((company) => {
      return !clients.some(
        (client) =>
          client.cloudApiKey === company.publicKey || isIdentityMatch(client.corporateIdentity, company.orgNumber),
      );
    });
  }

  private filterCompanies(blaCompanies: BlaCompany[]): BlaCompany[] {
    // exclude cloud companies without corp identity and public key
    return blaCompanies.filter((company: BlaCompany) => Boolean(company.orgNumber) && Boolean(company.publicKey));
  }

  private loadedDataSubject = new BehaviorSubject([]);
  private filterSubject = new BehaviorSubject({ includeHidden: false });


  private appendStatusText(cloudCompanies: BlaCompany[]): ImportTableRowType[] {
    const duplicatedOrgNumbers = this.getDuplicateOrgNumbers(cloudCompanies);

    return cloudCompanies.map((company) => {
      const statusText = [];

      if (company.hidden) {
        statusText.push('Dold');
      }

      if (duplicatedOrgNumbers.includes(company.orgNumber?.replace(/-/g, ''))) {
        statusText.push('Dubblett');
      }

      return { ...company, statusText: statusText.join(', ') };
    });
  }

  private getDuplicateOrgNumbers(cloudCompanies: BlaCompany[]): string[] {
    return cloudCompanies
      .filter((company) => Boolean(company.orgNumber))
      .filter((company, index, self) => self.findIndex((t) => t.orgNumber === company.orgNumber) !== index)
      .map((company) => company.orgNumber.replace(/-/g, ''));
  }
}
