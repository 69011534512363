<app-please-wait [hidden]="hidePleaseWait"></app-please-wait>

<div>
  <app-tabs #tabs class="mt-4">
    <app-tab tabTitle="Grunduppgifter">
      <input type="hidden" [(ngModel)]="_model.id" name="id" />
      <app-team-multi-selector
        containerClass="team-selector-standalone"
        [dispatches]="false"
        (OnTeamsChanged)="onTeamsChanged($event)"
        (OnTeamsInit)="OnTeamsInit()"
        [selector]="selector"
        [selectedIds]="selectedTeamIds">
      </app-team-multi-selector>
      <div class="w-full flex flex-col">
        <!-- Row 1 -->
        <div class="w-full flex flex-row gap-1">
          <div class="w-2/6">
            <app-corporate-identity-input
              #corporateIdentityInput
              labelClass="table-label"
              [isNewClient]="isNewClient"
              [foreignCorporateIdentity]="_model.foreignCorporateIdentity"
              (foreignCorporateIdentityChange)="onForeignCorporateIdentityChange($event)"
              [(corporateIdentity)]="_model.corporateIdentity"
              (corporateIdentityChange)="onCorporateIdentyChange($event)"
              (onButtonClick)="getCreditInformation()">
            </app-corporate-identity-input>
          </div>
          <div class="w-1/6">
            <label class="table-label" for="customerNumber">Kundnummer</label>
            <input
              id="customerNumber"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.customerNumber"
              maxlength="20" />
          </div>
          <div class="w-1/4">
            <label class="table-label" for="responsible">Klientansvarig</label>
            <div
              *ngIf="hasPermissionToChange$ | async as hasPermissionToChange"
              [pTooltip]="!hasPermissionToChange.value && disabledTooltip"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltip-extended-width"
              style="display: inline-block"
              class="w-full">
              <p-dropdown
                inputId="responsible"
                [options]="availableUsers$ | async"
                optionLabel="name"
                optionValue="id"
                [disabled]="!hasPermissionToChange.value"
                [(ngModel)]="_model.userId"
                (ngModelChange)="onResponsibleUserChanged($event)"
                styleClass="w-full"
                [autoOptionFocus]="false"
                [autoDisplayFirst]="false">
              </p-dropdown>
            </div>
          </div>
          <div class="w-1/4">
            <label class="table-label" for="clientType">Typ</label>
            <p-dropdown
              inputId="clientType"
              [options]="clientTypes$ | async"
              optionLabel="description"
              optionValue="name"
              [(ngModel)]="selectedType"
              (ngModelChange)="imDirty()"
              styleClass="w-full"
              [required]="true"
              placeholder="- Välj -"
              [autoOptionFocus]="false"
              [autoDisplayFirst]="false">
            </p-dropdown>
          </div>
        </div>

        <!-- Row 2 -->
        <div class="w-full flex flex-row gap-1">
          <div class="w-3/6">
            <label class="table-label" for="clientName">Namn</label>
            <input
              id="clientName"
              type="text"
              class="form-control"
              (change)="imDirty()"
              required
              [(ngModel)]="_model.name"
              maxlength="60" />
          </div>
          <div class="w-1/4">
            <label class="table-label" for="clientPhone">Telefon</label>
            <input
              id="clientPhone"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.phone"
              maxlength="15" />
          </div>
          <div class="w-1/4">
            <label class="table-label" for="clientCellPhone">Mobil</label>
            <input
              id="clientCellPhone"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.cellphone"
              maxlength="15" />
          </div>
        </div>

        <!-- Row 3 -->
        <div class="w-full flex flex-row gap-1">
          <div class="w-3/6">
            <label class="table-label" for="clientAddress">Adress</label>
            <input
              id="clientAddress"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.address"
              maxlength="60" />
          </div>
        </div>

        <!-- Row 4 -->
        <div class="w-full flex flex-row gap-1">
          <div class="w-1/6">
            <label class="table-label" for="clientZipCode">Postnummer</label>
            <input
              id="clientZipCode"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.zipCode"
              maxlength="6" />
          </div>
          <div class="w-2/4">
            <label class="table-label" for="clientCity">Ort</label>
            <input
              id="clientCity"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.city"
              maxlength="60" />
          </div>
          <div class="w-2/3">
            <label class="table-label" for="clientEmail">E-post</label>
            <input
              id="clientEmail"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.email"
              maxlength="60" />
          </div>
        </div>

        <!-- Row 5 -->
        <div class="w-full flex flex-row gap-1">
          <div class="w-3/6">
            <label class="table-label" for="clientCountry">Land</label>
            <input
              id="clientCountry"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.country"
              maxlength="60" />
          </div>
          <div class="w-3/6">
            <label class="table-label" for="clientWebPage">Hemsida</label>
            <input
              id="clientWebPage"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.webpage"
              maxlength="60" />
          </div>
        </div>

        <!-- Row 6 -->
        <div class="w-full flex flex-row gap-1">
          <div class="w-3/6">
            <label class="table-label" for="clientResidence">Säte</label>
            <input
              id="clientResidence"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.residence"
              maxlength="60" />
          </div>
          <div class="w-3/6">
            <label class="table-label" for="clientSignatory">Firmatecknare</label>
            <input
              id="clientSignatory"
              type="text"
              class="form-control"
              (change)="imDirty()"
              [(ngModel)]="_model.signatory"
              maxlength="60" />
          </div>
        </div>
      </div>

      <div class="overflow-auto">
        <app-team-badges label="" [options]="teams" optionLabel="name" roundedClass="rounded-md"></app-team-badges>
      </div>

      <div class="flex justify-between items-end mt-20">
        <div class="grid grid-flow-col gap-3">
          <div>
            <button
              type="button"
              class="btn btn-success"
              [disabled]="!isPossibleToCreateCloudDb"
              [pTooltip]="isPossibleToCreateCloudDb && createCloudDbTooltip && createCloudDbTooltipContent"
              [tooltipOptions]="createCloudDbTooltipOptions"
              (click)="onCreateCloudDbClick()">
              {{ createCloudDbButtonText }}
            </button>
            <ng-template #createCloudDbTooltipContent>
              <div class="font-semibold mb-3">{{ createCloudDbTooltip.title }}</div>
              <div [innerHTML]="createCloudDbTooltip.description | safeHtml"></div>
            </ng-template>
                </div>
          <div>
            <button
              *ngIf="_model?.advisoryTool"
              class="btn btn-primary"
              type="button"
              (click)="openGenerateAdvisoryReport()">
              Rådgivarrapport
            </button>
            <button
              *ngIf="!_model?.advisoryTool"
              class="btn btn-primary"
              (click)="openActivateAdvisoryComponent()"
              [disabled]="!isPossibleToActivateAdvisoryTool">
              Aktivera Rådgivarverktyget
            </button>
          </div>
          <div>
            <button
              *ngIf="_model?.sustainabilityReporting"
              class="btn btn-primary"
              (click)="gotoSustainabilityReporting()"
              [disabled]="!config.data.firm.sustainabilityReporting">
              Öppna hållbarhetsredovisning
            </button>
            <button
              *ngIf="!_model?.sustainabilityReporting && (activateOrDeactivateSustainabilityReportingInfo | async) as info"
              class="btn btn-primary"
              (click)="showActivateSustainabilityReportingDialog()"
              [disabled]="!info?.canActivate"
              [pTooltip]="info?.tooltip"
              tooltipPosition="top">
              Aktivera Hållbarhetsredovisning
            </button>
          </div>
        </div>

        <div>
          <button
            type="button"
            class="btn btn-success mr-3"
            (click)="saveClientAndUpdateCloud()"
            [disabled]="!isPossibleToUpdateCloudCompany"
            title="Spara klienten och samtidigt uppdatera molnföretaget">
            Spara och uppdatera
          </button>
          <button
            type="button"
            class="btn btn-success mr-3"
            (click)="addClient()"
            [disabled]="_model.archived || !_model.name || !selectedType"
            title="Spara bara i Byråstöd">
            Spara
          </button>
          <button type="button" class="btn btn-warning" (click)="closeDialog()">Stäng</button>
        </div>
      </div>
    </app-tab>
    <app-tab tabTitle="Verksamhetsuppgifter">
      <app-client-business-detail
        [client]="_model"
        (detailsEmitter)="harvestDetails($event)"
        (dirtyEmitter)="isDirty($event)">
      </app-client-business-detail>
    </app-tab>
    <app-tab tabTitle="Räkenskapsår" [disabled]="isNewClient">
      <app-client-financialyears
        [client]="_model"
        [defaultFinancialYear]="creditInformationDefaultFinancialYears"
        (update)="onFinancialYearUpdated($event)">
      </app-client-financialyears>
    </app-tab>
    <app-tab tabTitle="Uppdrag" [disabled]="isNewClient">
      <app-client-assignments [client]="_model" (updateEmitter)="callForUpdate($event)"></app-client-assignments>
    </app-tab>
    <app-tab tabTitle="Kontakter" [disabled]="isNewClient">
      <app-client-contacts [client]="_model"></app-client-contacts>
    </app-tab>
    <app-tab tabTitle="Dokument" [disabled]="isNewClient" [(active)]="documentTabActive">
      <app-file-browser [client]="_model" [active]="documentTabActive"></app-file-browser>
    </app-tab>
    <app-tab tabTitle="Noteringar" [disabled]="isNewClient">
      <app-client-todos [client]="_model"></app-client-todos>
    </app-tab>
    <app-tab tabTitle="Molntjänster" [disabled]="isNewClient" [(active)]="cloudServiceTabActive">
      <app-client-cloud-service
        [client]="_model"
        [active]="cloudServiceTabActive"
        (updated)="onClientUpdatedInCloudServiceTab($event)"
        (updatedWithoutSave)="onClientUpdatedInCloudServiceTab($event, false)">
      </app-client-cloud-service>
    </app-tab>
    <app-tab tabTitle="Tilläggstjänster" [disabled]="isNewClient || !_model.cloudApiKey">
      <app-client-integrations [client]="_model"></app-client-integrations>
    </app-tab>
    <app-tab tabTitle="PTL" [disabled]="isNewClient" [(active)]="amlTabActive">
      <app-client-aml [client]="_model" [active]="amlTabActive"></app-client-aml>
    </app-tab>
  </app-tabs>
  <a href="#" id="gotoBlapp" target="_blank"></a>
</div>
