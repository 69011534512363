import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FirmCollaboratorType } from '@app/core/state/types/firm-collaborator.types';
import {
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { FirmCollaboratorActions } from '@app/core/state/firm-collaborators/firm-collaborators.actions';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { FormsModule } from '@angular/forms';

interface FirmCollaboratorDialogData {
  clientId: number;
}

@Component({
  template: ` <input type="text" class="form-control" placeholder="Redovisningsbyrånyckel..." [(ngModel)]="firmKey" />
    <div class="flex justify-end mt-6 gap-2">
      <bl-frontend-button text="Avbryt" variant="secondary" (click)="close(null)"></bl-frontend-button>
      <bl-frontend-button text="Lägg till" [disabled]="!firmKey" (click)="addFirmCollaborator()"></bl-frontend-button>
    </div>`,
  standalone: true,
  imports: [CommonModule, FormsModule, BlFrontendButtonComponent],
})
export class AddFirmCollaboratorDialogComponent extends FormDialogBaseComponent<
  FirmCollaboratorDialogData,
  DialogResult
> {
  tableData$: Observable<FirmCollaboratorType[]>;
  columns: TableColumn[] = [
    { field: 'name', header: 'Kopplad BL Byrå' },
    { field: 'firmKey', header: 'Redovisningsbyrånyckel' },
    { field: 'action', header: 'Ta bort', width: '70px' },
  ];

  firmKey: string;
  private currentClientId: number;

  constructor(dialogRef: DynamicDialogRef, config: DynamicDialogConfig<FirmCollaboratorDialogData>) {
    super(dialogRef, config);

    this.currentClientId = config.data.clientId;
  }

  protected static override getDialogConfig() {
    return {
      header: 'Lägg till samarbetsbyrå',
      width: '600px',
    };
  }

  addFirmCollaborator() {
    this.store.dispatch(
      FirmCollaboratorActions.addFirmCollaborator({
        clientId: this.currentClientId,
        firmKey: this.firmKey,
      }),
    );
    this.close(null);
  }
}
