import {
  Component,
  ViewChildren,
  QueryList,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { Client } from 'src/app/core/entity/client';
import { ClientService } from 'src/app/core/services/clients.service';
import { CloudCompanyService } from 'src/app/core/services/cloud-company.service';
import { TabsComponent } from 'src/app/shared/components/tabs/tabs.component';
import { BaseCloudImportExportComponent } from './components/base-cloud-import-export.component';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { map, mergeMap, of } from 'rxjs';

interface Result extends DialogResult {
  updated?: boolean;
}

@Component({
  selector: 'app-compare-cloud-companies-dialog',
  templateUrl: './compare-cloud-companies-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareCloudCompaniesDialogComponent
  extends FormDialogBaseComponent<DialogData, Result>
  implements OnInit {
  @ViewChild(TabsComponent, { static: true })
  private tabsComponent: TabsComponent;

  @ViewChildren(BaseCloudImportExportComponent)
  private baseCloudImportExportComponents: QueryList<BaseCloudImportExportComponent>;

  clients: Client[];
  loadingData = true;
  showWorkingSpinner = false;
  updated = false;
  includeHidden = false;

  get hideAddButton() {
    const component = this.getSelectedBaseComponent() || { hideAddButton: false };
    return component.hideAddButton;
  }

  get hasAccessToShowHiddenDatabases() {
    const showHiddenDatabasesForAllUsers$ = this.store.select(FirmSelectors.selectFirmProperties).pipe(
      map((firmProperties) => firmProperties?.show_hidden_databases_for_all_users ?? 'false'),
      map((setting) => setting === 'true'),
    );

    return this.store.select(AuthSelectors.selectIsFirmAdmin).pipe(
      mergeMap((isFirmAdmin) => isFirmAdmin ? of(isFirmAdmin) : showHiddenDatabasesForAllUsers$),
    );
  }

  constructor(
    protected override ref: DynamicDialogRef,
    protected override config: DynamicDialogConfig<DialogData>,
    private clientService: ClientService,
    private cloudCompanyService: CloudCompanyService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(ref, config);
  }

  protected static override getDialogConfig(): DynamicDialogConfig<DialogData> {
    return {
      header: 'Jämför mot Företag i molnet',
      modal: true,
      dismissableMask: true,
      draggable: false,
      width: '1200px',
    };
  }

  protected override onInitialized = () => {
    this.initLoadingOfAllClients();
    this.initLoadingOfCloudCompanies();
  };

  onTabChange() {
    this.loadDataForCurrentTab();
  }

  onAddButton() {
    this.getSelectedBaseComponent().execute();
  }

  private loadDataForCurrentTab() {
    const baseComponent = this.getSelectedBaseComponent();
    if (!baseComponent) {
      return;
    }

    baseComponent.init(this.clients).loadList();
  }

  private getSelectedBaseComponent(): BaseCloudImportExportComponent {
    if (!this.tabsComponent || !this.baseCloudImportExportComponents) {
      return null;
    }

    const { currentTabIndex } = this.tabsComponent;
    return this.baseCloudImportExportComponents.toArray()[currentTabIndex];
  }

  private initLoadingOfAllClients() {
    this.clientService
      .getAllClients()
      .pipe(this.takeUntilDestroyed())
      .subscribe((clients: Client[]) => {
        this.clients = clients;
      });
  }

  private initLoadingOfCloudCompanies() {
    this.cloudCompanyService
      .getCloudCompanies()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.loadingData = false;
          this.changeDetectorRef.markForCheck();
          setTimeout(() => this.loadDataForCurrentTab(), 0);
        },
        complete: () => {
          this.loadingData = false;
          this.changeDetectorRef.markForCheck();
        },
      });
  }
}
