import { createActionGroup, props } from '@ngrx/store';
import { FirmCollaboratorMetaType, FirmCollaboratorType } from '../types/firm-collaborator.types';

export const FirmCollaboratorActions = createActionGroup({
  source: 'FirmCollaborators',
  events: {
    'Load Firm Collaborators For Client If Missing': props<{ clientId: number }>(),
    'Load Firm Collaborators For Client Succeeded': props<{
      clientId: number;
      firmCollaboratorMeta: FirmCollaboratorMetaType;
    }>(),
    'Load Firm Collaborators For Client Failed': props<{ error: unknown }>(),

    'Add Firm Collaborator': props<{ clientId: number; firmKey: string }>(),
    'Remove Firm Collaborator': props<{ clientId: number; firmKey: string }>(),

    'Update Firm Collaborator': props<{ clientId: number; userId: number; firmKeys: string[] }>(),
    'Update Firm Collaborator Succeeded': props<{ clientId: number; firmCollaborators: FirmCollaboratorType[] }>(),
    'Update Firm Collaborator Failed': props<{ error: unknown }>(),

    'Clear Firm Collaborators For Client': props<{ clientId: number }>(),
  },
});
