<section *appPartnerProgramAccess class="bg-bl-blue-light-100 min-h-screen">
  <div id="partnerprogram-container" class="ml-32">
    <p class="text-2xl font-bold text-bl-grey-900 py-8">Partnerprogrammet</p>

    <p-tabView [(activeIndex)]="activeIndex">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span class="text-sm">Översikt</span>
        </ng-template>
        <app-partnerprogram-overview [level]="level" [firm]="firm" [levels]="levels" (openTab)="activeIndex = $event"></app-partnerprogram-overview>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span class="text-sm">Förmånsnivåer</span>
        </ng-template>
        <app-partnerprogram-benefits [level]="level?.value" [firm]="firm" [data]="benefits" [levels]="levels" (openTab)="activeIndex = $event"></app-partnerprogram-benefits>
      </p-tabPanel>
      <p-tabPanel *ngIf="level?.value >= 1 || level?.value === 0">
        <ng-template pTemplate="header">
          <span class="text-sm">Resurser</span>
        </ng-template>
        <app-partnerprogram-resources [level]="level?.value || 1" [data]="benefits" [firm]="firm" (openTab)="activeIndex = $event"></app-partnerprogram-resources>
      </p-tabPanel>
      <p-tabPanel *appFeatureToggle="'showMyFirm'">
        <ng-template pTemplate="header">
          <span class="text-sm font-medium text-bl-grey-900">Hitta min byrå-profil</span>
        </ng-template>
        <app-partnerprogram-profile [level]="level?.value"></app-partnerprogram-profile>
      </p-tabPanel>
    </p-tabView>
  </div>
</section>
