import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerProgramProfileCardComponent } from '../partnerprogram-profile/partnerprogram-profile-card.component';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { PartnerFirm } from '@app/core/state/types/partner.types';

@Component({
  selector: 'app-partnerprogram-keycert-card',
  template: `
    <app-partnerprogram-profile-card [header]="header" [style]="hasFallenOut ? 'opacity: 0.6;' : ''">
      <div class="flex flex-row" [ngClass]="{ 'opacity-60': hasFallenOut }">
        <div class="w-2/3 mt-2">
          <p *ngIf="firm?.keyUserCertifications > 0">
            <strong>Key Users</strong> på byrån: {{ firm?.keyUserCertifications }}
          </p>
          <p *ngIf="firm?.keyUserCertifications === 0">
            För att låsa upp förmåner på de högre nivåerna <strong>Krom, Titan och Platina</strong> behöver byrån ha
            minst en certifierad <strong>Key User.</strong>
          </p>
          <p *ngIf="firm?.keyUserCertifications > 0">
            Certifieringen ger byrån full tillgång till förmånerna på nivåerna <strong>Krom-Platina</strong> när
            respektive nivå uppnås.
          </p>
        </div>
        <div class="w-1/3 flex flex-col items-center">
          <div class="w-40 h-40 rounded-md bg-bl-grey-700 flex flex-col items-center justify-center">
            <i
              class="fa-duotone fa-key-skeleton fa-2xl text-6xl"
              style="--fa-primary-color: #c58124; --fa-secondary-color: #fdc75a; --fa-secondary-opacity: 1;"></i>
          </div>
        </div>
      </div>
      <div footer>
        <bl-frontend-button [text]="callToAction" (click)="openLink()" [disabled]="hasFallenOut"></bl-frontend-button>
      </div>
    </app-partnerprogram-profile-card>
  `,
  styles: [``],
  standalone: true,
  imports: [CommonModule, PartnerProgramProfileCardComponent, BlFrontendButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramKeyCertCardComponent {
  @Input() hasFallenOut: boolean;
  @Input() firm: PartnerFirm | null;

  get header() {
    if (!this.firm?.keyUserCertifications) {
      return 'Bli certifierad Key User!';
    }

    return this.firm.keyUserCertifications > 1 ? 'Key User' : 'Grattis till certifieringen Key User!';
  }

  get callToAction() {
    if (!this.firm?.keyUserCertifications) {
      return 'Läs mer och ansök!';
    }

    return 'Certifiera fler Key Users!';
  }

  openLink() {
    window.open(
      'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram/key-user-certifiering__8986',
      '_blank',
    );
  }
}
