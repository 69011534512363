import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { Client } from 'src/app/core/entity/client';
import { CloudCompanyService } from 'src/app/core/services/cloud-company.service';
import { BlaCompany } from 'src/app/core/entity/bla-company';
import { BaseCloudImportExportComponent } from './base-cloud-import-export.component';
import { ClientService } from 'src/app/core/services/clients.service';
import { ClientBlaCompanyComposite } from 'src/app/core/entity/client-blacompany-composite';
import { catchError, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { ClientActions } from 'src/app/core/state/clients/clients.actions';
import { CLIENT_RESPONSIBLE } from '@app/shared/misc/constants';
import { isIdentityMatch } from '@app/core/misc/identity-matching';
import { toClientTransformer, toClientTypeTransformer } from '@app/core/state/transformers/transformers';

type SyncTableRowType = ClientBlaCompanyComposite & { statusText: string };

@Component({
  selector: 'app-sync-companies-between-byst-cloud',
  templateUrl: './sync-companies-between-byst-cloud.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseCloudImportExportComponent, useExisting: SyncCompaniesBetweenBystCloudComponent }],
})
export class SyncCompaniesBetweenBystCloudComponent extends BaseCloudImportExportComponent {
  @Output() updating: EventEmitter<void> = new EventEmitter<void>();
  @Output() updated: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected override cloudCompanyService: CloudCompanyService,
    private clientService: ClientService,
    private store: Store<AppState>,
  ) {
    super(cloudCompanyService);
    this.companies$ = this.loadedDataSubject.asObservable();
  }

  listMessage = this.LIST_MESSAGES.EMPTY_CLOUD_COMPANIES; // todo - byta
  columns = [
    { field: 'client.corporateIdentity', header: 'Orgnr' },
    { field: 'blaCompany.name', header: 'Namn enligt företagsdatabas' },
    { field: 'client.customerNumber', header: 'Kundnr' },
    { field: 'client.responsibleUser', header: CLIENT_RESPONSIBLE },
    { field: 'blaCompany.publicKey', header: 'Molndatabasnyckel' },
    { field: 'statusText', header: 'Info databas' },
  ];

  companies$: Observable<SyncTableRowType[]>;
  selectedCompanies: ClientBlaCompanyComposite[] = [];

  get hasSelectedCompanies() {
    return this.selectedCompanies.length > 0;
  }

  loadList(): void {
    const clientFilterFn = (client: Client) => !client.archived;

    this.getListData(clientFilterFn).subscribe({
      next: (response: ClientBlaCompanyComposite[]) => {
        this.loadedDataSubject.next(
          this.appendResponsibleUserPropToClients(this.appendStatusText(response)),
        );
      },
      // eslint-disable-next-line no-console
      error: (err: unknown) => console.log('Error loading import list', err),
    });
  }

  execute(): void {
    if (!this.hasSelectedCompanies) {
      return;
    }

    this.updating.emit();

    const updates$ = this.selectedCompanies.map((companyComposite: ClientBlaCompanyComposite) => {
      const client = {
        ...toClientTypeTransformer.transform(companyComposite.client),
        cloudApiKey: companyComposite.blaCompany.publicKey,
      };
      const teamIds = companyComposite.client.teams.map((team) => team.id);

      return this.clientService.upsertClientTypeWithTeams(client, teamIds).pipe(
        map((c) => ({ success: true, data: toClientTransformer.transform(c) })),
        tap(() => this.store.dispatch(ClientActions.loadAllClients())),
        catchError(() => of({ success: false, data: companyComposite.client })),
      );
    });

    forkJoin(updates$).subscribe({
      next: (response) => {
        const hasSomeErrors = response.some((item) => !item.success);

        if (hasSomeErrors) {
          this.store.dispatch(
            ToastActions.showErrorMessage({
              summary: 'Ett fel har uppstått',
              detail: 'En eller fler av de valda Klienterna kunde inte uppdateras.',
            }),
          );
        }
        const updatedClients = response.filter((item) => item.success).map((item) => item.data);
        this.selectedCompanies = [];
        this.updateClients(updatedClients);
        this.loadList();
        this.updated.emit();
      },
      complete: () => {
        this.updated.emit();
      },
    });
  }

  // Implementation of abstract parent method
  protected filterList(clients: Client[], blaCompanies: BlaCompany[]): ClientBlaCompanyComposite[] {
    return this.filterCloudCompanies(blaCompanies).reduce(
      (acc: ClientBlaCompanyComposite[], company: BlaCompany): ClientBlaCompanyComposite[] => {
        const client = clients.find((c) => !c.cloudApiKey && isIdentityMatch(c.corporateIdentity, company.orgNumber));

        if (client) {
          acc.push(new ClientBlaCompanyComposite(client, company));
        }

        return acc;
      },
      [],
    );
  }

  private filterCloudCompanies(blaCompanies: BlaCompany[]): BlaCompany[] {
    // exclude cloud companies without corp identity and public key
    return blaCompanies.filter((company: BlaCompany) => Boolean(company.orgNumber) && Boolean(company.publicKey));
  }

  private loadedDataSubject = new BehaviorSubject([]);

  private appendResponsibleUserPropToClients(cloudCompanies: ClientBlaCompanyComposite[]): ClientBlaCompanyComposite[] {
    return cloudCompanies.map((composite) => {
      const responsibleUser = composite.client.responsible?.name || '';
      return { ...composite, client: { ...composite.client, responsibleUser } };
    });
  }

  private appendStatusText(cloudCompanies: ClientBlaCompanyComposite[]): SyncTableRowType[] {
    const duplicatedOrgNumbers = this.getDuplicateOrgNumbers(cloudCompanies);

    return cloudCompanies.map((composite) => {
      const statusText = [];

      if (composite.blaCompany.hidden) {
        statusText.push('Dold');
      }

      if (duplicatedOrgNumbers.includes(composite.blaCompany.orgNumber?.replace(/-/g, ''))) {
        statusText.push('Dubblett');
      }

      return { ...composite, statusText: statusText.join(', ') };
    });
  }

  private getDuplicateOrgNumbers(cloudCompanies: ClientBlaCompanyComposite[]): string[] {
    return cloudCompanies
      .map((composite) => composite.blaCompany)
      .filter((company) => Boolean(company.orgNumber))
      .filter((company, index, self) => self.findIndex((t) => t.orgNumber === company.orgNumber) !== index)
      .map((company) => company.orgNumber.replace(/-/g, ''));
  }
}
