import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { ButtonProps } from '@bl/components';
import { toggleClass } from './helpers';
import { BlFrontendBaseComponent } from './bl-frontend-base.component';

@Component({
  selector: 'bl-frontend-button',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class BlFrontendButtonComponent extends BlFrontendBaseComponent<ButtonProps> {
  @Input() text: ButtonProps['text'] = '';
  @Input() variant: ButtonProps['variant'] = 'primary';
  @Input() brand: ButtonProps['brand'] = 'firmsupport';
  @Input() disabled: ButtonProps['disabled'] = false;
  @Input() type: ButtonProps['type'] = 'button';
  @Input() fullWidth: ButtonProps['fullWidth'] = false;
  @Input() icon: ButtonProps['icon'];
  @Input() iconVariant: ButtonProps['iconVariant'];
  @Input() iconSize: ButtonProps['iconSize'];
  @Input() iconAnimation: ButtonProps['iconAnimation'];

  protected override getComponentName(): string {
    return 'Button';
  }

  protected override getProps(): ButtonProps {
    return {
      text: this.text,
      brand: this.brand,
      variant: this.variant,
      disabled: this.disabled,
      type: this.type,
      fullWidth: this.fullWidth,
      icon: this.icon,
      iconVariant: this.iconVariant,
      iconSize: this.iconSize,
      iconAnimation: this.iconAnimation,
    };
  }

  protected override onChanges = (changes: SimpleChanges) => {
    this.handleRefElementWidth(changes);
  };

  handleRefElementWidth(changes: SimpleChanges): void {
    if ('fullWidth' in changes) {
      const condition = changes['fullWidth'].currentValue;
      toggleClass(this.ref.nativeElement, 'w-full', condition);
    }
  }
}
