import { createFeature, createReducer, on } from '@ngrx/store';
import { FeatureType } from '../types';
import { FeatureActions } from './feature.actions';

export const initialFeaturesState: FeatureType = {
  messaging: false,
  showDueComplienceButton: false,
  showMyFirm: false,
};

export const featuresFeature = createFeature({
  name: 'features',
  reducer: createReducer(
    initialFeaturesState,
    on(FeatureActions.featuresUpdated, (_state, { features }) => ({ ...features })),
  ),
});
