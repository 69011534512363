import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Client } from 'src/app/core/entity/client';
import { CloudCompanyService } from 'src/app/core/services/cloud-company.service';
import { BlaCompany } from 'src/app/core/entity/bla-company';
import { BaseCloudImportExportComponent } from './base-cloud-import-export.component';
import { CLIENT_RESPONSIBLE } from '@app/shared/misc/constants';
import { DialogService } from 'primeng/dynamicdialog';
import { isIdentityMatch } from '@app/core/misc/identity-matching';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import { CreateCloudDbWizardService } from '@app/shared/components/cloud-db-export-wizard/create-clouddb-wizard.service';

@Component({
  selector: 'app-export-companies-to-cloud',
  templateUrl: './export-companies-to-cloud.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: BaseCloudImportExportComponent, useExisting: ExportCompaniesToCloudComponent },
    DialogService,
    CreateCloudDbWizardService,
  ],
})
export class ExportCompaniesToCloudComponent extends BaseCloudImportExportComponent {
  @Output() updated: EventEmitter<void> = new EventEmitter<void>();

  listMessage = this.LIST_MESSAGES.EMPTY_CLIENTS;
  columns = [
    { field: 'corporateIdentity', header: 'Orgnr' },
    { field: 'name', header: 'Namn' },
    { field: 'customerNumber', header: 'Kundnr' },
    { field: 'responsibleUser', header: CLIENT_RESPONSIBLE },
    { field: 'firmType', header: 'Företagstyp' },
    { field: 'action', sortDisabled: true },
  ];

  clients$: Observable<Client[]>;
  selectedClientToExport: Client = null;
  disableAddButtons = false;
  exporting = false;
  private loadedDataSubject = new BehaviorSubject([]);

  override get hideAddButton(): boolean {
    return true;
  }

  // eslint-disable-next-line max-params
  constructor(
    protected override cloudCompanyService: CloudCompanyService,
    private changeDetectorRef: ChangeDetectorRef,
    private allInclusiveService: AllInclusiveService,
    private createCloudDbWizardService: CreateCloudDbWizardService,
  ) {
    super(cloudCompanyService);

    this.clients$ = this.loadedDataSubject.pipe(
      map((clients: Client[]) => this.appendResponsibleUserPropToClients(clients)),
    );
  }

  loadList(): void {
    const clientFilterFn = (client: Client) => client.type.name !== 'PERSON' && !client.archived;

    this.getListData(clientFilterFn).subscribe({
      next: (response) => {
        this.loadedDataSubject.next(response);
      },
    });
  }

  onAddButton(client: Client) {
    this.selectedClientToExport = client;

    if (this.allInclusiveService.isLoggedInWithBLTAService) {
      this.showExportWizardForOnboarding(client);
      return;
    }

    this.showExportWizardForCreateCloudDB(client);
  }

  execute(): void { }

  // Implementation of abstract parent method
  protected filterList(clients: Client[], blaCompanies: BlaCompany[]): Client[] {
    return this.filterClients(clients).filter(
      (client) => !blaCompanies.some((blaCompany) => isIdentityMatch(blaCompany.orgNumber, client.corporateIdentity)),
    );
  }

  private filterClients(clients: Client[]): Client[] {
    // exclude clients that is private person and without corp identity and has public key already
    return clients.filter(
      (client: Client) => client.type.name !== 'PERSON' && client.corporateIdentity && !client.cloudApiKey,
    );
  }

  private showExportWizardForOnboarding(client: Client) {
    this.createCloudDbWizardService.runOnboardingWizard(client).subscribe();
  }

  private showExportWizardForCreateCloudDB(client: Client) {
    this.disableAddButtons = true;

    this.createCloudDbWizardService.runCreateCloudDbWizard(client).subscribe({
      next: () => {
        this.loadList();
        this.updated.emit();
      },
      error: () => {
        this.resetButtonState();
      },
      complete: () => {
        this.resetButtonState();
      },
    });
  }

  private resetButtonState() {
    this.disableAddButtons = false;
    this.selectedClientToExport = null;
    this.changeDetectorRef.markForCheck();
  }

  private appendResponsibleUserPropToClients(clients: Client[]): Client[] {
    return clients.map((client) => ({ ...client, responsibleUser: client.responsible?.name || '' }));
  }
}
