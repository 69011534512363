<div class="flex mt-10">
  <div class="w-2/3">
    <div class="flex justify-between items-center mb-4">
      <h4 class="font-semibold">
        PTL Risknivå<i
          class="fal fa-question-circle text-bl-blue-light-500 px-2 cursor-help"
          [pTooltip]="tooltipContent"
          [tooltipOptions]="tooltipOptions"></i>
      </h4>
      <div>
        <bl-frontend-button
          *ngIf="hasActiveConnection$ | async"
          class="mr-1"
          text="Koppla befintlig PTL-kontroll"
          variant="secondary"
          (click)="showAttachAmlDialog()"></bl-frontend-button>
        <bl-frontend-button
          [text]="createAmlButtonText$ | async"
          variant="primary"
          (click)="createAml()"></bl-frontend-button>
      </div>
    </div>

    <ng-container *ngIf="hasActiveConnection$ | async">
      <p class="mt-8 text-base font-semibold text-bl-grey-600">Pågående PTL-kontroller</p>
      <ng-container *ngIf="inProgressAmlData$ | async as inProgressAmlData; else loading">
        <ng-container *ngIf="inProgressAmlData.length > 0; else noInProgressAmlTemplate">
          <div
            *ngFor="let row of inProgressAmlData"
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ $implicit: row, contentTemplate: inProgressContentTemplate }"></div>
        </ng-container>
      </ng-container>
    </ng-container>

    <p class="mt-8 text-base font-semibold text-bl-grey-600">Avslutade PTL-kontroller</p>
    <ng-container *ngIf="doneAmlData$ | async as doneAmlData; else loading">
      <ng-container *ngIf="doneAmlData.length > 0; else noDoneAmlTemplate">
        <div
          *ngFor="let row of doneAmlData"
          [ngTemplateOutlet]="rowTemplate"
          [ngTemplateOutletContext]="{ $implicit: row, contentTemplate: doneContentTemplate }"></div>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="!(hasActiveConnection$ | async)">
    <div class="border-bl-grey-200 border-l-2 mx-8"></div>
    <div class="w-1/3">
      <app-due-compliance-activation-box></app-due-compliance-activation-box>
    </div>
  </ng-container>
</div>

<ng-template #loading> Laddar... </ng-template>
<ng-template #noInProgressAmlTemplate> Det finns inga pågående PTL-kontroller på klienten. </ng-template>
<ng-template #noDoneAmlTemplate> Det finns inga avslutade PTL-kontroller på klienten. </ng-template>

<ng-template #rowTemplate let-row let-contentTemplate="contentTemplate">
  <div class="border border-bl-grey-200 rounded-lg shadow-sm p-4 mb-2">
    <div class="flex justify-between items-center">
      <ng-container [ngTemplateOutlet]="contentTemplate" [ngTemplateOutletContext]="{ $implicit: row }"> </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #inProgressContentTemplate let-row>
  <div class="flex flex-col">
    <div class="text-base font-semibold">{{ row.amlId }}</div>
    <bl-frontend-badge
      [text]="row.status | amlStatusDescription"
      [variant]="row.status | amlStatusVariantType"
      class="mt-1"></bl-frontend-badge>
    <div class="text-sm text-bl-grey-500 mt-1">Skapad: {{ row.createdAt | date : 'yyyy-MM-dd' }}</div>
  </div>
  <div class="flex items-center">
    <bl-frontend-meatballmenu [items]="inProgressMenuItemsInContextOf(row)"></bl-frontend-meatballmenu>
  </div>
</ng-template>

<ng-template #doneContentTemplate let-row>
  <div class="flex flex-col">
    <div class="text-base font-semibold">{{ row.amlId || 'Manuellt inlagd riskbedömning' }}</div>
    <div class="flex">
      <bl-frontend-badge
        [text]="row.risk | amlRiskDescription"
        [variant]="row.risk | amlRiskVariantType"
        class="mt-1"></bl-frontend-badge>
      <bl-frontend-badge
        *ngIf="row.amlId"
        [text]="row.status | amlStatusDescription"
        [variant]="row.status | amlStatusVariantType"
        class="mt-1 ml-1"></bl-frontend-badge>
    </div>
    <div class="text-sm text-bl-grey-500 mt-1">Riskbedömd: {{ row.updatedAt | date : 'yyyy-MM-dd' }}</div>
  </div>
  <div class="flex items-center">
    <bl-frontend-meatballmenu [items]="doneMenuItemsInContextOf(row)"></bl-frontend-meatballmenu>
  </div>
</ng-template>
