import { createSelector } from '@ngrx/store';
import { firmCollaboratorFeature } from './firm-collaborators.reducer';

const errorCodeToTextMap = {
  PAYING_WITHOUT_EDIT_RIGHTS: 'Ni är betalande byrå, men saknar rätt att koppla samarbetsbyrå',
  MISSING_FIRM_CUSTOMER_NUMBER: 'Denna klient saknar information om byråkundnummer',
  FIRM_COLLABORATOR: 'Ni är samarbetsbyrå och kan inte redigera kopplade byråer',
  FIRM_COLLABORATOR_WITHOUT_COLLABORATORS: 'Ni har ingen rättighet att redigera denna klients kopplade byråer',
  GENERAL_CUSTOMER_NUMBER_RULE_ERROR: 'Ett okänt fel uppstod, troligen saknas information om byråkundnummer',
};

const { selectFirmCollaborators } = firmCollaboratorFeature;

const selectFirmCollaboratorStateByClientId = (clientId: number) =>
  createSelector(selectFirmCollaborators, (state) => state[clientId] || null);

const selectFirmCollaboratorsByClientId = (clientId: number) =>
  createSelector(selectFirmCollaboratorStateByClientId(clientId), (state) => state?.collaborators || null);

const selectFirmCollaboratorNamesByClientId = (clientId: number) =>
  createSelector(selectFirmCollaboratorsByClientId(clientId), (collaborators) => {
    if (!collaborators) {
      return null;
    }
    return collaborators.map((firm) => firm.name);
  });

const selectMetaUserIdByClientId = (clientId: number) =>
  createSelector(selectFirmCollaboratorStateByClientId(clientId), (state) => state?.userId || null);

const canEditCollaboratorsForClientId = (clientId: number) =>
  createSelector(selectFirmCollaboratorStateByClientId(clientId), (state) => {
    if (!state) {
      return false;
    }
    return !state?.validationError
  });

const existsFirmCollaboratorsForClientInState = (clientId: number) =>
  createSelector(selectFirmCollaboratorStateByClientId(clientId), (state) => Boolean(state));

const selectCollaboratorsText = (clientId: number) =>
  // eslint-disable-next-line complexity
  createSelector(selectFirmCollaboratorStateByClientId(clientId), (state) => {
    if (!state || state.collaborators === null) {
      return 'Laddar...';
    }

    if (state.validationError) {
      return errorCodeToTextMap[state.validationError] ?? 'Ett okänt fel uppstod';
    }

    if (state.collaborators.length === 0) {
      return 'Inga kopplade byråer hittades';
    }

    return state.collaborators.map((collaborator) => collaborator.name).join(', ');
  });

export const FirmCollaboratorSelectors = {
  selectFirmCollaboratorsByClientId,
  selectFirmCollaboratorNamesByClientId,
  selectMetaUserIdByClientId,
  selectFirmCollaboratorStateByClientId,
  existsFirmCollaboratorsForClientInState,
  canEditCollaboratorsForClientId,
  selectCollaboratorsText,
};
