import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { filter } from 'rxjs';
import { Client } from 'src/app/core/entity/client';
import { CloudDbData } from 'src/app/core/entity/cloud-db-data';
import { validateDateFn } from '@app/core/entity/validation';
import {
  areDaysBetweenDatesOk,
  areMonthsBetweenFinancialYearsOk,
  isFirstDateAfterSecondDate,
} from '@app/shared/misc/dates';

import { ExportWizardActions } from '@app/core/state/export-wizard/export-wizard.actions';
import { toClientTypeTransformer } from '@app/core/state/transformers/transformers';
import { exportWizardFeature } from '@app/core/state/export-wizard/export-wizard.feature';
import { CommonModule } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarComponent } from '../calendar/calendar.component';
import { FocusTrapModule } from 'primeng/focustrap';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogData, DialogResult, FormDialogBaseComponent } from '../form-dialog-base/form-dialog-base.component';

export interface WizardConfig extends DialogData {
  client: Client;
}
export interface WizardResponse extends DialogResult {
  clientId: number;
  name?: string;
  financialYear: {
    start: string;
    end: string;
  };
  accountingMethod: string;
  vatAccountingInterval: string;
}

interface WizardFinancialYearGroup {
  start: FormControl<string>;
  end: FormControl<string>;
}

interface WizardForm {
  clientId: FormControl<number>;
  name: FormControl<string>;
  financialYear: FormGroup<WizardFinancialYearGroup>;
  accountingMethod: FormControl<string>;
  vatAccountingInterval: FormControl<string>;
}

@Component({
  selector: 'app-export-wizard-dialog',
  templateUrl: './export-wizard-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RadioButtonModule, FocusTrapModule, CalendarComponent],
})
export class ExportWizardDialogComponent extends FormDialogBaseComponent<WizardConfig, WizardResponse, WizardForm> {
  exportCompanyName: string;
  accountingMethods: { name: string; key: string }[] = [
    { name: 'Kontantmetoden', key: 'cash' },
    { name: 'Faktureringsmetoden', key: 'billing' },
  ];

  vatAccountingIntervals: { name: string; key: string }[] = [
    { name: 'Månad', key: 'month' },
    { name: 'Kvartal', key: 'quarter' },
    { name: 'Helår', key: 'year' },
    { name: 'Ingen / Momsfri verksamhet', key: 'none' },
  ];

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.close(this.getFormValue());
  }

  get isFormValid() {
    return this.form.valid;
  }

  get submitTitle() {
    if (!this.form?.errors) {
      return '';
    }

    const message: string[] = Object.values(this.form.errors).reduce((acc, curr) => {
      if (!curr) {
        return acc;
      }

      return acc.concat(curr.message);
    }, []);

    return message ? message.join('\n') : '';
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<WizardConfig>, 'data'> {
    return {
      width: '550px',
      resizable: false,
      draggable: false,
      modal: true,
      header: 'Extra information för företagsdatabasen',
    };
  }

  protected override onInitialized = () => {
    if (!this.config.data.client) {
      return;
    }

    this.createForm();
    this.store
      .select(exportWizardFeature.selectData)
      .pipe(
        filter((data) => Boolean(data)),
        this.takeUntilDestroyed(),
      )
      .subscribe((data) => this.setDefaultFormValues(data));

    this.store.dispatch(
      ExportWizardActions.getDefaultValues({ client: toClientTypeTransformer.transform(this.config.data.client) }),
    );
  };

  private getFormValue(): WizardResponse {
    return this.form.value as WizardResponse;
  }

  private setDefaultFormValues(defaultFormValues: CloudDbData) {
    this.form.patchValue({ ...defaultFormValues, name: defaultFormValues.name ?? '' });
  }

  private createForm() {
    this.form = this.builder.group<WizardForm>(
      {
        clientId: new FormControl(this.config.data.client.id),
        name: new FormControl(null),
        financialYear: this.builder.group({
          start: new FormControl('', validateDateFn({ key: 'start' })),
          end: new FormControl('', validateDateFn({ key: 'end' })),
        }),
        accountingMethod: new FormControl(null, Validators.required),
        vatAccountingInterval: new FormControl(null, Validators.required),
      },
      { validators: this.validateRequiredFields() },
    );
  }

  private getAccountingMethodValue(form: FormGroup<WizardForm>) {
    return form.controls.accountingMethod.value ? null : { message: 'Redovisningsmetod är inte vald' };
  }

  private getVatAccountingIntervalValue(form: FormGroup<WizardForm>) {
    return form.controls.vatAccountingInterval.value ? null : { message: 'Momsredovisning är inte vald' };
  }

  private getFromValue(start: string) {
    return start ? null : { message: 'Räkenskapsårets från datum är inte vald' };
  }

  private getToValue(end: string) {
    return end ? null : { message: 'Räkenskapsårets till datum är inte vald' };
  }

  private getIsEndAfterStartValue(start: string, end: string) {
    return isFirstDateAfterSecondDate(end, start) ? null : { message: 'Till datumet måste vara efter Från datumet' };
  }

  private getAreDaysBetweenOkValue(start: string, end: string) {
    return areDaysBetweenDatesOk(start, end) ? null : { message: 'Det måste vara minst 2 dagar i ett räkenskapsår' };
  }

  private getIsMonthsBetweenOk(start: string, end: string) {
    return areMonthsBetweenFinancialYearsOk(start, end)
      ? null
      : { message: 'Räkenskapsåret får inte vara längre än 18 månader' };
  }

  private validateRequiredFields(): ValidatorFn {
    return (form: FormGroup<WizardForm>): ValidationErrors | null => {
      const accountingMethod = this.getAccountingMethodValue(form);
      const vatAccountingInterval = this.getVatAccountingIntervalValue(form);
      const start = form.controls.financialYear.controls.start.value;
      const end = form.controls.financialYear.controls.end.value;
      const from = this.getFromValue(start);
      const to = this.getToValue(end);
      const isEndAfterStart = this.getIsEndAfterStartValue(start, end);
      const isDaysBetweenOk = this.getAreDaysBetweenOkValue(start, end);
      const isMonthsBetweenOk = this.getIsMonthsBetweenOk(start, end);

      const errors = {
        accountingMethod,
        vatAccountingInterval,
        start: from,
        end: to,
        isEndAfterStart,
        isDaysBetweenOk,
        isMonthsBetweenOk,
      };

      if (Object.values(errors).some((error) => error)) {
        return errors;
      }

      return null;
    };
  }
}
