import { isNullOrUndefined } from '@app/core/entity/validation';
import moment from 'moment';

export const MIN_CALENDAR_DATE = new Date(2010, 0, 1);
export const MAX_CALENDAR_DATE = new Date(2099, 11, 31);

export function isFirstDateAfterSecondDate(firstDate: string, secondDate: string): boolean {
  const invalidFirstDate = Number.isNaN(Date.parse(firstDate));
  const invalidEndDate = Number.isNaN(Date.parse(secondDate));

  if (invalidFirstDate) {
    return false;
  }

  if (!invalidFirstDate && invalidEndDate) {
    return true;
  }

  const firstDateDate = new Date(firstDate);
  const secondDateDate = new Date(secondDate);

  return firstDateDate > secondDateDate;
}

export function isFirstDateEqualToSecondDate(firstDate: string, secondDate: string): boolean {
  const invalidFirstDate = Number.isNaN(Date.parse(firstDate));
  const invalidEndDate = Number.isNaN(Date.parse(secondDate));

  if (invalidFirstDate || invalidEndDate) {
    return false;
  }

  const firstDateDate = new Date(firstDate);
  const secondDateDate = new Date(secondDate);

  return firstDateDate.getTime() === secondDateDate.getTime();
}

export function areMonthsBetweenFinancialYearsOk(start: string, end: string): boolean {
  if (!start || !end) {
    return false;
  }

  const diff = Math.abs(moment(start).diff(moment(end), 'months'));
  return diff < 18;
}

export function areDaysBetweenDatesOk(start: string, end: string, days = 2): boolean {
  if (!start || !end) {
    return false;
  }

  const diff = Math.abs(moment(start).diff(moment(end), 'days'));
  return diff >= days;
}

export function getYears(startYear: number, futureYearsCount: number): number[] {
  if (isNullOrUndefined(startYear)) {
    return [];
  }

  if (isNullOrUndefined(futureYearsCount)) {
    return [];
  }

  const years: number[] = [];
  const currentYear = new Date().getUTCFullYear();

  for (let year = currentYear + futureYearsCount; year >= startYear; year -= 1) {
    years.push(year);
  }

  return years;
}

export function getRelevantYearAsString(daysAgo = 0): string {
  const prevDate = new Date(new Date().getTime() - daysAgo * 24 * 60 * 60 * 1000);
  const currentDate = new Date();

  if (prevDate < currentDate) {
    return String(prevDate.getUTCFullYear());
  }

  return String(currentDate.getUTCFullYear());
}

function getDateWithoutTime(date: Date): string {
  return date.toISOString().split('T')[0];
}

function isDate(value: any): value is Date {
  return value instanceof Date;
}

function isDateString(value: any): boolean {
  if (typeof value !== 'string') {
    return false;
  }

  const parsedDate = Date.parse(value);
  return !isNaN(parsedDate);
}

export function getCurrentIsoDateWithoutTime(): string {
  return getDateWithoutTime(new Date());
}

export function getIsoDateWithoutTime(date: Date | string | null | undefined): string {
  if (!date) {
    return getCurrentIsoDateWithoutTime();
  }

  if (isDate(date)) {
    return getDateWithoutTime(date);
  }

  if (isDateString(date)) {
    return getDateWithoutTime(new Date(date));
  }

  return getCurrentIsoDateWithoutTime();
}

export function getIsoDateWithTime(date: Date | string | null | undefined): string {
  if (!date) {
    return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  }

  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function getShortMonthAndYear(date: string): string {
  const monthYear = moment(date).locale('sv').format('MMM YYYY');
  return monthYear.charAt(0).toUpperCase() + monthYear.slice(1);
}

export function getLongMonthAndYear(date: string, capitalize = true): string {
  const monthYear = moment(date).locale('sv').format('MMMM YYYY');
  if (!capitalize) {
    return monthYear;
  }

  return monthYear.charAt(0).toUpperCase() + monthYear.slice(1);
}

export function getMonthsBetweenDates(start: string, end: string): number {
  const startDate = moment(start);
  const endDate = moment(end);

  return endDate.diff(startDate, 'months');
}

export function getPreviousMonth(): string {
  return moment().subtract(1, 'months').toDate().toISOString();
}

export function getLastDateOfPreviousMonth(): string {
  return moment().subtract(1, 'months').endOf('month').toDate().toISOString();
}

export function getLocalIsoDateWithTime(date: Date | string | null | undefined): string {
  if (!date) {
    return '';
  }

  const utcDate = new Date(`${date}Z`);
  return moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss');
}
