import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { AppState } from '@app/core/state/appState';
import { PartnerProgramActions } from '@app/core/state/partnerprogram/partnerprogram.actions';
import { PartnerProgramSelectors } from '@app/core/state/partnerprogram/partnerprogram.selectors';
import { PartnerFirm, RefundDto } from '@app/core/state/types/partner.types';
import { BlFrontendIconButtonComponent } from '@app/core/wrappers/bl-frontend-iconbutton.component';
import { BlFrontendTooltipComponent } from '@app/core/wrappers/bl-frontend-tooltip.component';
import { getCurrentIsoDateWithoutTime, getLongMonthAndYear, getShortMonthAndYear } from '@app/shared/misc/dates';
import { TodayDate } from '@app/shared/misc/today.date';
import { BlCurrencyPipe } from '@app/shared/pipes/bl-currency.pipe';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, filter, map, mergeMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-partnerprogram-chart',
  standalone: true,
  imports: [CommonModule, BlFrontendTooltipComponent, BlFrontendIconButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './partnerprogram-chart.component.html',
  styles: [
    `
      .icon-2xl {
        @apply text-2xl;
      }
      .bar-value {
        @apply rounded;
      }
      .bar-value .bar-value-tooltip {
        @apply hidden;
        @apply z-50;
      }
      .bar-value:hover .bar-value-tooltip {
        @apply block;
      }
      .current-month {
        @apply opacity-40;
      }
      .chart-group:hover .bar-value {
        @apply opacity-100;
      }
      .chart-group:hover .chart-label {
        @apply font-semibold;
      }
    `,
  ],
})
export class PartnerProgramChartComponent implements OnDestroy {
  @Input() hasFallenOut: boolean;

  months = new TodayDate().SHORT_MONTHS;

  displayedData: RefundDto[] = [];
  private selectedStartDateSubject: BehaviorSubject<string>;
  private maxBarHeight = 0;
  private onDestroySub: Subject<void>;
  private currency: BlCurrencyPipe;
  private chartData: RefundDto[] = [];
  private currentIndex = 0;

  constructor(
    private store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.onDestroySub = new Subject();
    this.currency = new BlCurrencyPipe('sv');
    this.selectedStartDateSubject = new BehaviorSubject(getCurrentIsoDateWithoutTime());

    this.selectedStartDateSubject.pipe(takeUntil(this.onDestroySub)).subscribe((year) => {
      this.store.dispatch(PartnerProgramActions.load(year));
    });

    this.selectedStartDateSubject
      .pipe(
        mergeMap(() => this.store.select(PartnerProgramSelectors.firm)),
        filter((data) => Boolean(data)),
        map((data) => this.mapToChartData(data)),
        takeUntil(this.onDestroySub),
      )
      .subscribe((data) => {
        this.chartData = data;
        this.currentIndex = Math.max(0, this.chartData.length - 8);
        this.updateDisplayedData();
      });
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  get previousButtonVisible() {
    return this.currentIndex > 0;
  }

  get nextButtonVisible() {
    return this.currentIndex < this.chartData.length - 8;
  }

  previousDate() {
    if (this.currentIndex <= 0) {
      return;
    }

    this.currentIndex--;
    this.updateDisplayedData();
  }

  nextDate() {
    if (this.currentIndex >= this.chartData.length - 8) {
      return;
    }

    this.currentIndex++;
    this.updateDisplayedData();
  }

  calculateBarHeightForPeriod(data: RefundDto): {
    refund: number;
    units: number;
    currentMonth: boolean;
    shortDate: string;
    longDate: string;
    refundPercent: number;
    tooltip: string;
  } {
    if (!data) {
      return null;
    }
    const { date, refund, units } = data;
    const refundString = this.currency.transform(refund, true);
    const currentMonth = new Date(date).getMonth() === new Date().getMonth();
    const currentMonthTooltip = `Beräknad återbäring 
    BL Pro-paket: ${refundString} kr`;
    const pastMonthTooltip = `Återbäring BL Pro-
    paket: ${refundString} kr`;
    const tooltip = currentMonth ? currentMonthTooltip : pastMonthTooltip;

    return {
      refund,
      units,
      currentMonth,
      shortDate: getShortMonthAndYear(String(date)),
      longDate: getLongMonthAndYear(String(date)),
      refundPercent: this.calcPercent(refund),
      tooltip,
    };
  }

  // calculates percentage of the bar and min value is 0.5% so something shows in the view (by design)
  private calcPercent = (valueToCalc: number) => Math.max(0.5, (valueToCalc / this.maxBarHeight) * 100);

  private mapToChartData(data: PartnerFirm): RefundDto[] {
    if (!data) {
      return [];
    }

    const sorted = data.refunds.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    return sorted;
  }

  private calculateBarMaxHeight(data: RefundDto[]) {
    const maxRefund = data.reduce((max, item) => Math.max(item.refund, max), 0);
    return Math.max(maxRefund);
  }

  private updateDisplayedData(): void {
    this.displayedData = this.chartData.slice(this.currentIndex, this.currentIndex + 8);
    this.maxBarHeight = this.calculateBarMaxHeight(this.displayedData);
    this.changeDetectorRef.markForCheck();
  }
}
