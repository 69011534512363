import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { BaseEffects } from '../core/base.effects';
import { FirmCollaboratorActions } from './firm-collaborators.actions';
import { AppState } from '../appState';
import { Store } from '@ngrx/store';
import { ClientService } from '@app/core/services/clients.service';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';
import { FirmCollaboratorSelectors } from './firm-collaborators.selectors';
import { ToastActions } from '../toast/toast.actions';

@Injectable()
export class FirmCollaboratorEffects extends BaseEffects {
  loadIfCloudApiKeyIsMissing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmCollaboratorActions.loadFirmCollaboratorsForClientIfMissing),
      concatLatestFrom(({ clientId }) =>
        this.store.select(FirmCollaboratorSelectors.existsFirmCollaboratorsForClientInState(clientId)),
      ),
      filter(([, existingFirmCollaborators]) => !existingFirmCollaborators),
      switchMap(([{ clientId }]) =>
        this.clientService.getFirmCollaborators(clientId).pipe(
          map((firmCollaboratorMeta) =>
            FirmCollaboratorActions.loadFirmCollaboratorsForClientSucceeded({ clientId, firmCollaboratorMeta }),
          ),
          catchError((error: unknown) => of(FirmCollaboratorActions.loadFirmCollaboratorsForClientFailed({ error }))),
        ),
      ),
    ),
  );

  addFirmCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmCollaboratorActions.addFirmCollaborator),
      concatLatestFrom(({ clientId }) =>
        this.store.select(FirmCollaboratorSelectors.selectFirmCollaboratorStateByClientId(clientId)),
      ),
      map(([{ clientId, firmKey }, { userId, collaborators }]) => {
        const newListOfCollaboratorKeys = collaborators.map((collaborator) => collaborator.firmKey).concat(firmKey);
        return FirmCollaboratorActions.updateFirmCollaborator({
          clientId,
          userId,
          firmKeys: newListOfCollaboratorKeys,
        });
      }),
    ),
  );

  removeFirmCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmCollaboratorActions.removeFirmCollaborator),
      concatLatestFrom(({ clientId }) =>
        this.store.select(FirmCollaboratorSelectors.selectFirmCollaboratorStateByClientId(clientId)),
      ),
      map(([{ clientId, firmKey: firmKeyToRemove }, { userId, collaborators }]) => {
        const newListOfCollaboratorKeys = collaborators
          .map((collaborator) => collaborator.firmKey)
          .filter((firmKey) => firmKey !== firmKeyToRemove);

        return FirmCollaboratorActions.updateFirmCollaborator({
          clientId,
          userId,
          firmKeys: newListOfCollaboratorKeys,
        });
      }),
    ),
  );

  updateFirmCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmCollaboratorActions.updateFirmCollaborator),
      switchMap(({ clientId, userId, firmKeys }) =>
        this.clientService.updateFirmCollaborators(clientId, userId, firmKeys).pipe(
          tap((firmCollaborators) => {
            if (firmCollaborators.length !== firmKeys.length) {
              this.showFailedToUpdateError();
            }
          }),
          map((firmCollaborators) =>
            FirmCollaboratorActions.updateFirmCollaboratorSucceeded({ clientId, firmCollaborators }),
          ),
          catchError((error: unknown) => of(FirmCollaboratorActions.updateFirmCollaboratorFailed({ error }))),
        ),
      ),
    ),
  );

  private showFailedToUpdateError() {
    this.store.dispatch(
      ToastActions.showErrorMessage({
        summary: 'Uppdateringen misslyckades',
        detail: 'Kontrollera Redovisningsbyrånyckeln och försök igen.',
      }),
    );
  }

  constructor(actions$: Actions, store: Store<AppState>, private clientService: ClientService) {
    super(actions$, store);
  }
}
