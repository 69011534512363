import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ActivityStateMultiSelectorComponent } from '@app/shared/components/activity-states-multi-selector/activity-states-multi-selector.component';
import { FileBrowserComponent } from 'src/app/shared/components/new-client/components/tabs/file-browser/file.browser.component';
import { PleaseWaitComponent } from 'src/app/shared/components/please-wait/please-wait.component';
import { TeamBadgesComponent } from '@app/firm-settings/teams/components/team-badges/team-badges.component';
import { ActivityCommentIconComponent } from './components/activity-comment-icon/activity-comment-icon.component';
import { AssignmentSelectorComponent } from './components/assignment-selector/assignment-selector.component';
import { AuthRoleSelectorsComponent } from './components/auth-role-selector/auth-role-selector.component';
import { BlaIconButtonComponent } from './components/bla-icon-button/bla-icon-button.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ExportWizardDialogComponent } from './components/cloud-db-export-wizard/export-wizard-dialog.component';
import { DeadlineValueComponent } from './components/deadline/deadline-value.component';
import { FeatureToggle } from './components/feature-toggle/feature-toggle.component';
import { MessagePanelComponent } from './components/message-panel/message-panel.component';
import { MultiselectComponent } from './components/multiselect-dropdown/multiselect-dropdown.component';
import { MultiselectWithAllDropdownComponent } from './components/multiselect-dropdown/multiselect-with-all-dropdown.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ClientActivityListDialogComponent } from './components/new-client/components/client-activity-list-dialog/client-activity-list-dialog.component';
import { ConnectCloudCompanyDialogComponent } from './components/new-client/components/connect-cloud-company-dialog.component';
import { PermissionSelectorComponent } from './components/new-client/components/permission-selector/permission-selector.component';
import { ClientAssignmentsComponent } from './components/new-client/components/tabs/client-assignments/client.assignments.component';
import { ClientBusinessDetailComponent } from './components/new-client/components/tabs/client-business-detail/client.business.detail.component';
import { ClientContactsComponent } from './components/new-client/components/tabs/client-contacts/client.contacts.component';
import { ClientFinancialYearsComponent } from './components/new-client/components/tabs/client-financialyears/client.financialyears.component';
import { ClientIntegraionsComponent } from './components/new-client/components/tabs/client-integrations/client-integrations.component';
import { ClientTodosComponent } from './components/new-client/components/tabs/client-todos/client.todos.component';
import { ApplicationLinksComponent } from './components/new-client/components/tabs/cloud-service/application-links.component';
import { ClientCloudServiceComponent } from './components/new-client/components/tabs/cloud-service/client-cloud-service.component';
import { RuleSelectorComponent } from './components/rule-selector/rule.selector.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { BystTableModule } from './components/table/table.module';
import { TabComponent } from './components/tabs/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TaskCommentIconComponent } from './components/task-comment-icon/task-comment-icon.component';
import { TaskSelectorComponent } from './components/task-selector/task-selector.component';
import { UserMultiSelectorComponent } from './components/user-multi-selector/user-multi-selector.component';
import { UserSelector } from './components/user-selector/user-selector.component';
import { BlCurrencyPipe } from './pipes/bl-currency.pipe';
import { GetIconPipe } from './pipes/get-icon.pipe';
import { PrimeNgModule } from './primeng.module';
import { ActivityStateSelectorComponent } from './components/activity-states-selector/activity-states-selector.component';
import { MonthsSelector } from './components/month-selector/month-selector.component';
import { YearSelector } from './components/year-selector/year-selector.component';
import { RefreshButton } from './components/refresh-button/refresh-button.component';
import { MultiSelectDropDownIncludingAllComponent } from './components/multiselect-dropdown/multiselect-dropdown-including-all.component';
import { ApplicationFilesComponent } from './components/application-files.component/application-files.component';
import { EditFinancialYearDialogComponent } from './components/new-client/components/tabs/client-financialyears/edit-financialyear-dialog.component';
import { NewClientDialog } from './components/new-client/new.client.dialog';
import { ActivityDetailsDialog } from './components/activity-details-dialog/activity.details.dialog';
import { TaskGroupsSelectorComponent } from './components/task-groups-selector/task-groups-selector.component';
import { PasteValidCorporateIdentityDirective } from './directives/paste-valid-corporate-identity.directive';
import { FeatureToggleDirective } from './components/feature-toggle/feature-toggle.directive';
import { PickListComponent } from './components/picklist/picklist.component';
import { TeamMultiSelectorComponent } from './components/team-multi-selector/team-multi-selector.component';
import { PickListSourceDirective } from './components/picklist/template-source.directive';
import { PickListTargetDirective } from './components/picklist/template-target.directive';
import { AdminPrivilegeDirective } from './components/admin-privilege/admin-privilege.directive';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';
import { ClientAmlComponent } from './components/new-client/components/tabs/client-aml/client-aml.component';
import {
  AmlRiskDescriptionPipe,
  AmlRiskVariantTypePipe,
  AmlStatusDescriptionPipe,
  AmlStatusVariantTypePipe,
} from './pipes/aml';
import { BlFrontendMeatBallMenuComponent } from '@app/core/wrappers/bl-frontend-meatballmenu.component';
import { DueComplianceActivationBoxComponent } from './components/due-compliance-activation-box/due-compliance-activation-box.component';
import { ShowIfBlFirmServiceDirective } from './directives/show-if-bl-firm-service.directive';
import { ProgressTimelineModule } from './components/progress-timeline/progress-timeline.module';
import { PartnerProgramAccessDirective } from './components/partner-program-access/partner-program-access.directive';
import { CorporateIdentityInputComponent } from './components/corporate-identity-input/corporate-identity-input.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FirmCollaboratorComponent } from './components/new-client/components/tabs/cloud-service/firm-collaborator.component';
import { BlFrontendCheckboxComponent } from '@app/core/wrappers/bl-frontend-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    BystTableModule,
    PleaseWaitComponent,
    AssignmentSelectorComponent,
    TaskSelectorComponent,
    CalendarComponent,
    DeadlineValueComponent,
    UserMultiSelectorComponent,
    ActivityStateMultiSelectorComponent,
    ExportWizardDialogComponent,
    TaskGroupsSelectorComponent,
    PasteValidCorporateIdentityDirective,
    TeamMultiSelectorComponent,
    MultiSelectDropDownIncludingAllComponent,
    TeamBadgesComponent,
    ActivityStateSelectorComponent,
    ActivityDetailsDialog,
    AdminPrivilegeDirective,
    ProgressTimelineModule,
    FeatureToggleDirective,
    BlFrontendButtonComponent,
    BlFrontendBadgeComponent,
    BlFrontendCheckboxComponent,
    BlFrontendMeatBallMenuComponent,
    DueComplianceActivationBoxComponent,
    ShowIfBlFirmServiceDirective,
    PartnerProgramAccessDirective,
    FirmCollaboratorComponent,

    // Pipes
    BlCurrencyPipe,
    AmlRiskDescriptionPipe,
    AmlRiskVariantTypePipe,
    AmlStatusDescriptionPipe,
    AmlStatusVariantTypePipe,
    SafeHtmlPipe,
  ],
  declarations: [
    AuthRoleSelectorsComponent,
    ConnectCloudCompanyDialogComponent,
    ClientIntegraionsComponent,
    ClientCloudServiceComponent,
    ApplicationLinksComponent,
    TabComponent,
    ClientBusinessDetailComponent,
    ClientFinancialYearsComponent,
    EditFinancialYearDialogComponent,
    ClientAssignmentsComponent,
    ClientActivityListDialogComponent,
    ClientContactsComponent,
    FileBrowserComponent,
    ClientTodosComponent,
    TabsComponent,
    RuleSelectorComponent,
    StatusIconComponent,
    ActivityCommentIconComponent,
    TaskCommentIconComponent,
    BlaIconButtonComponent,
    GetIconPipe,
    MessagePanelComponent,
    NavbarComponent,
    MultiselectWithAllDropdownComponent,
    MultiselectComponent,
    PermissionSelectorComponent,
    UserSelector,
    FeatureToggle,
    MonthsSelector,
    YearSelector,
    RefreshButton,
    ApplicationFilesComponent,
    NewClientDialog,
    PickListComponent,
    PickListSourceDirective,
    PickListTargetDirective,
    ClientAmlComponent,
    CorporateIdentityInputComponent,
  ],
  exports: [
    PrimeNgModule,
    AuthRoleSelectorsComponent,
    PleaseWaitComponent,
    TabComponent,
    ActivityStateMultiSelectorComponent,
    ClientBusinessDetailComponent,
    ClientFinancialYearsComponent,
    EditFinancialYearDialogComponent,
    ClientAssignmentsComponent,
    ClientContactsComponent,
    FileBrowserComponent,
    ClientTodosComponent,
    TabsComponent,
    UserMultiSelectorComponent,
    RuleSelectorComponent,
    BlCurrencyPipe,
    TaskSelectorComponent,
    AssignmentSelectorComponent,
    StatusIconComponent,
    ActivityCommentIconComponent,
    TaskCommentIconComponent,
    BlaIconButtonComponent,
    ExportWizardDialogComponent,
    GetIconPipe,
    MessagePanelComponent,
    BystTableModule,
    NavbarComponent,
    MultiselectWithAllDropdownComponent,
    MultiselectComponent,
    CalendarComponent,
    DeadlineValueComponent,
    UserSelector,
    FeatureToggle,
    FeatureToggleDirective,
    ActivityStateSelectorComponent,
    MonthsSelector,
    YearSelector,
    RefreshButton,
    MultiSelectDropDownIncludingAllComponent,
    ApplicationFilesComponent,
    NewClientDialog,
    ActivityDetailsDialog,
    TaskGroupsSelectorComponent,
    PasteValidCorporateIdentityDirective,
    PickListComponent,
    TeamMultiSelectorComponent,
    PickListSourceDirective,
    PickListTargetDirective,
    TeamBadgesComponent,
    AdminPrivilegeDirective,
    BlFrontendButtonComponent,
    BlFrontendCheckboxComponent,
    ShowIfBlFirmServiceDirective,
    ProgressTimelineModule,
    PartnerProgramAccessDirective,
    CorporateIdentityInputComponent,
    SafeHtmlPipe,
  ],
  providers: [],
})
export class BystSharedModule { }
