import { ChangeDetectionStrategy, Component, Input, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { ClientGuideForm, FinancialFormGroup, FinancialYearFormGroup } from '../new-client-guide.types';
import { toSignal } from '@angular/core/rxjs-interop';
import { first, map, Observable, Subject, switchMap } from 'rxjs';

interface StatusState {
  financialYearMessage: string | null;
  isFinancialYearStartDateInvalid: boolean;
  isFinancialYearEndDateInvalid: boolean;
}

@Component({
  selector: 'app-step3-financial-information',
  template: ` <ng-container [formGroup]="form">
    <p class="font-bold">Redovisningsmetod</p>
    <div class="mb-6 flex">
      <div *ngFor="let method of accountingMethods" class="flex mr-6">
        <div class="mr-2">
          <p-radioButton
            [inputId]="method.key"
            [value]="method.key"
            name="accountingMethod"
            formControlName="accountingMethod"></p-radioButton>
        </div>
        <div>
          <label [for]="method.key">
            {{ method.name }}
          </label>
        </div>
      </div>
    </div>
    <div class="mb-6">
      <p class="font-bold">Momsredovisning</p>
      <div class="flex">
        <div *ngFor="let method of vatAccountingIntervals" class="flex mr-6">
          <div class="mr-2">
            <p-radioButton
              [inputId]="method.key"
              [value]="method.key"
              name="vatAccountingInterval"
              formControlName="vatAccountingInterval"></p-radioButton>
          </div>
          <div>
            <label [for]="method.key">
              {{ method.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="financialYear">
      <p class="font-bold">Räkenskapsår</p>
      <div class="w-full grid grid-cols-2 gap-1">
        <div class="flex items-center">
          <span class="mr-1">Från:</span>
          <app-calendar
            formControlName="start"
            appendTo="body"
            [defaultDate]="null"
            [invalid]="status()?.isFinancialYearStartDateInvalid">
          </app-calendar>
        </div>
        <div class="flex items-center">
          <span class="mr-1">Till:</span>
          <app-calendar
            formControlName="end"
            appendTo="body"
            [defaultDate]="null"
            [invalid]="status()?.isFinancialYearEndDateInvalid">
          </app-calendar>
        </div>
        <p class="text-bl-red-600 mt-2 col-span-2" *ngIf="status()?.financialYearMessage">
          {{ status().financialYearMessage }}
        </p>
      </div>
    </div>
  </ng-container>`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, BlFrontendButtonComponent, BystSharedModule, ReactiveFormsModule],
})
export class Step3FinancialInformationComponent implements OnInit {
  @Input() formGroupName: keyof ClientGuideForm;

  exportCompanyName: string;
  accountingMethods: { name: string; key: string }[] = [
    { name: 'Kontantmetoden', key: 'cash' },
    { name: 'Faktureringsmetoden', key: 'billing' },
  ];

  vatAccountingIntervals: { name: string; key: string }[] = [
    { name: 'Månad', key: 'month' },
    { name: 'Kvartal', key: 'quarter' },
    { name: 'Helår', key: 'year' },
    { name: 'Ingen / Momsfri verksamhet', key: 'none' },
  ];

  form: FormGroup<FinancialFormGroup>;
  formStatusToSignalSubject: Subject<Observable<StatusState>>;
  status: Signal<StatusState>;

  constructor(private rootFormGroup: FormGroupDirective) {
    this.formStatusToSignalSubject = new Subject();

    // Note: Workaround/fix for a "catch 22" situation.
    // Need to use the subject to get around that the "form" is not initialized yet (can't get it from rootFormGroup here)
    // and has to be initilaized in onInit, but toSignal must be initialized in construtor (angular errors if it's in onInit).
    this.status = toSignal(
      this.formStatusToSignalSubject.pipe(
        first(),
        switchMap((formStatusObservable) => formStatusObservable),
      ),
    );
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    const statusChanges$ = this.form.statusChanges.pipe(map(() => this.validateFinancialYearForm()));
    this.formStatusToSignalSubject.next(statusChanges$);
  }

  private validateFinancialYearForm(): StatusState {
    const isStartDateDirty = this.isFormControlDirty(this.getFinancialYearChildControl('start'));
    const isEndDateDirty = this.isFormControlDirty(this.getFinancialYearChildControl('end'));

    const showMessageIfDateFieldsIsDirty = isStartDateDirty && isEndDateDirty;

    return {
      financialYearMessage: showMessageIfDateFieldsIsDirty ? this.getFinancialYearErrorMessage() : null,
      isFinancialYearStartDateInvalid: this.isFinancialYearStartDateInvalid(),
      isFinancialYearEndDateInvalid: this.isFinancialYearEndDateInvalid(),
    };
  }

  private getFinancialYearErrorMessage(): string | null {
    const financialYearErrors = this.getFinancialYearControl().errors;

    if (financialYearErrors?.dateOrder) {
      return 'Till datumet måste vara efter Från datumet';
    }

    if (financialYearErrors?.dateBetween) {
      return 'Det måste vara minst 2 dagar i ett räkenskapsår';
    }

    if (financialYearErrors?.monthsBetween) {
      return 'Räkenskapsåret får inte vara längre än 18 månader';
    }

    return null;
  }

  private isFinancialYearStartDateInvalid() {
    return this.isFormControlDirtyAndInvalid(this.getFinancialYearChildControl('start'));
  }

  private isFinancialYearEndDateInvalid() {
    return this.isFormControlDirtyAndInvalid(this.getFinancialYearChildControl('end'));
  }

  private isFormControlDirty(control: FormControl) {
    return control.dirty;
  }

  private isFormControlDirtyAndInvalid(control: FormControl) {
    return this.isFormControlDirty(control) && control.invalid;
  }

  private getFinancialYearControl() {
    return this.form.controls.financialYear;
  }

  private getFinancialYearChildControl(name: keyof FinancialYearFormGroup) {
    return this.getFinancialYearControl().controls[name];
  }
}
